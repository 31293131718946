<template>
  <widget-mask @click.native="hide">
    <div class="widget-toast" ref="toast">
      <span class="widget-toast__icon"></span>
      <span class="widget-toast__text">{{ $bus.toast.content }}</span>
    </div>
  </widget-mask>
</template>

<script>
import anime from '@/utils/anime'
import WidgetMask from '../widget-mask'

export default {
  components: {
    WidgetMask
  },
  data() {
    return {
      timer: null
    }
  },
  methods: {
    hide() {
      this.$bus.toast.show = false
      clearTimeout(this.timer)
      this.timer = null
    },
    autoHide() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hide()
      }, 2000)
    }
  },
  watch: {
    '$bus.toast': {
      handler(current) {
        if (current.show) {
          anime.sliderY(this.$refs.toast, '-30%', this.autoHide)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-toast {
  @include flexac;
  position: relative;
  width: px2vw(550);
  padding: 0 px2vw(20);
  min-height: px2vh(40);
  margin: px2vh(30) auto 0;
  text-align: center;
  border-radius: 5px;
  background-color: $el-form;

  .widget-toast__icon {
    /* width: px2vw(20); */
    /* height: px2vh(18); */
    width: 18px;
    height: 16px;
    background: url('../../assets/images/icon-warning.png') top center / 100%
      100% no-repeat;
  }

  .widget-toast__text {
    padding-left: px2vw(10);
    font-size: 16px;
    color: $el-text;

    @include lg {
      font-size: 14px;
    }

    // @include md {
    //   font-size: 12px;
    // }

    // @include sm {
    //   font-size: 12px;
    // }
  }
}
</style>
