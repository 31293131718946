import {
  teamColorList,
  mapScale,
  // mapConfig,
  mapOptions,
  icons,
  controls,
  matchColorList,
  // maxBounds,
  playSpeed
} from './const'

export const getTeamColor = index => teamColorList[index]
export const getMapScale = (mapId = 1) => mapScale[mapId]
export const getMapOptions = (options = {}) => ({ ...mapOptions, ...options })
export const getControls = () => controls
export const getIcons = () => icons
export const lerp = (start, end, t) => start * (1 - t) + end * t
export const getMatchColor = index => matchColorList[index]
// 获取速度
export const getPlaySpeed = () => playSpeed
// 计算地图的中心点x
export const getCenterX = (bottom_left_x, top_right_x) => {
  const centerPoint = (Math.abs(bottom_left_x) + Math.abs(top_right_x)) / 2
  if (Math.abs(bottom_left_x) < Math.abs(top_right_x)) {
    return Math.abs(top_right_x) - centerPoint
  } else {
    return centerPoint - Math.abs(bottom_left_x)
  }
}
// 计算地图的中心点y
export const getCenterY = (bottom_left_y, top_right_y) => {
  const centerPoint = (Math.abs(bottom_left_y) + Math.abs(top_right_y)) / 2
  if (Math.abs(bottom_left_y) < Math.abs(top_right_y)) {
    return Math.abs(top_right_y) - centerPoint
  } else {
    return centerPoint - Math.abs(bottom_left_y)
  }
}
// 通过team color获取icon color
export const getIconIndex = color => {
  let indexNumber = 0
  teamColorList.forEach((teamColor, index) => {
    if (teamColor === color) {
      indexNumber = index
    }
  })
  return indexNumber
}
