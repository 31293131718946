<template>
  <div class="widget-dot">
    <span
      class="widget-dot__icon"
      :class="color === '#000' ? 'widget-dot__icon--border' : ''"
      :style="{ backgroundColor: color }"
    ></span>
    <span class="widget-dot__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-dot {
  @include flexac;

  .widget-dot__icon {
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: $el-text;

    &.widget-dot__icon--border {
      border: 1px solid $el-em;
    }
  }

  .widget-dot__text {
    padding-left: px2vw(4);
    font-size: 12px;
    color: $el-em;

    @include lg {
      font-size: 10px;
    }

    // @include md {
    //   font-size: 8px;
    // }

    // @include sm {
    //   font-size: 8px;
    // }
  }
}
</style>
