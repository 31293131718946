import request from './request'

const match = data => {
  return request({
    url: '/api/match',
    method: 'post',
    data
  })
}

export default {
  match
}
