var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-map',{ref:"mapRef",staticClass:"widget-map",attrs:{"crs":_vm.crs,"options":_vm.mapOptions,"maxBounds":_vm.maxBounds,"center":[_vm.mapConfig.centerX, _vm.mapConfig.centerY]},on:{"ready":_vm.onMapReady}},[_c('l-image-overlay',{ref:"imageRef",attrs:{"url":_vm.mapConfig.url,"bounds":_vm.mapConfig.bounds}}),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.movementFlag &&
        _vm.$bus.movementData.length
      )?_vm._l((_vm.$bus.movementData),function(player,index){return _c('l-polyline',{key:("line" + index),ref:"polylineRef",refInFor:true,attrs:{"color":_vm.getTeamPolylineColor(player),"lat-lngs":_vm.getMovementLatLngs(player)}})}):_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.activepointFlag &&
        _vm.$bus.activePointData.length
      )?_vm._l((_vm.$bus.activePointData),function(player,index){return _c('l-circle-marker',{key:("active" + index),attrs:{"lat-lng":_vm.getLatLngs(player),"radius":_vm.getActivepointRadius,"stroke":false,"fill":true,"fillColor":_vm.getTeamActivePointColor(player),"fillOpacity":1},on:{"click":function($event){return _vm.$bus.handleGA('active_point','success')}}},[_c('l-popup',{staticClass:"popup-point",attrs:{"options":{ closeButton: false }}},[(_vm.$bus.getIsMuti)?_c('div',{staticClass:"popup-point-matchid",style:(_vm.getMatchIdStyle(player.matchId))},[_vm._v(" "+_vm._s(player.matchId)+" ")]):_vm._e(),_c('div',{staticClass:"popup-point-title"},[_c('p',[_vm._v("#"+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+ _vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_name))])]),_c('div',{staticClass:"popup-point-subtitle"},[_vm._v(" Active Point ("+_vm._s(player.members.length)+") ")]),_vm._l((player.members),function(member){return _c('div',{key:member.userId,staticClass:"popup-point-text"},[_vm._v(" "+_vm._s(_vm.$bus.onGetPlayerInfo(member.userId, player.teamId) === undefined ? "" : _vm.$bus.onGetPlayerInfo(member.userId, player.teamId).nickname)+" ")])})],2)],1)}):_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.deadpointFlag &&
        _vm.$bus.diePointData.length
      )?_vm._l((_vm.$bus.diePointData),function(player,index){return _c('l-marker',{key:("deadpoint" + index),attrs:{"lat-lng":_vm.getLatLngs(player),"icon":_vm.getPointIconUrl('deadpoint', player)},on:{"click":function($event){return _vm.$bus.handleGA('dead_point','success')}}},[_c('l-popup',{staticClass:"popup-point",attrs:{"options":{ closeButton: false }}},[(_vm.$bus.getIsMuti)?_c('div',{staticClass:"popup-point-matchid",style:(_vm.getMatchIdStyle(player.matchId))},[_vm._v(" "+_vm._s(player.matchId)+" ")]):_vm._e(),_c('div',{staticClass:"popup-point-title"},[_c('p',[_vm._v("#"+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+ _vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_name))]),_c('p',[_vm._v(_vm._s(_vm.getTime(player.time)))])]),_c('div',{staticClass:"popup-point-subtitle"},[_c('span',[_vm._v("DEAD (1)")])]),_c('div',{staticClass:"popup-point-text"},[_vm._v(" "+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, player.teamId) === undefined ? "" : _vm.$bus.onGetPlayerInfo(player.userId, player.teamId).nickname)+" ")])])],1)}):_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.landingpointFlag &&
        _vm.$bus.landingPointData.length
      )?_vm._l((_vm.$bus.landingPointData),function(player,index){return _c('l-marker',{key:("landingpoint" + index),attrs:{"lat-lng":_vm.getLatLngs(player),"icon":_vm.getPointIconUrl('landingpoint', player)},on:{"click":function($event){return _vm.$bus.handleGA('landing_point','success')}}},[_c('l-popup',{staticClass:"popup-point",attrs:{"options":{ closeButton: false }}},[(_vm.$bus.getIsMuti)?_c('div',{staticClass:"popup-point-matchid",style:(_vm.getMatchIdStyle(player.matchId))},[_vm._v(" "+_vm._s(player.matchId)+" ")]):_vm._e(),_c('div',{staticClass:"popup-point-title"},[_c('p',[_vm._v("#"+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+ _vm.$bus.onGetPlayerInfo(player.userId, player.teamId).team_name))]),_c('p',[_vm._v(_vm._s(_vm.getTime(player.time)))])]),_c('div',{staticClass:"popup-point-subtitle"},[_c('span',[_vm._v("LAND (1)")])]),_c('div',{staticClass:"popup-point-text"},[_vm._v(" "+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, player.teamId) === undefined ? "" : _vm.$bus.onGetPlayerInfo(player.userId, player.teamId).nickname)+" ")])])],1)}):_vm._e(),(_vm.$bus.mapControlsFlag.bombFlag && _vm.$bus.bombData.length)?_vm._l((_vm.$bus.bombData),function(player,index){return _c('l-circle',{key:("bomb" + index),attrs:{"lat-lng":_vm.getLatLngs(player),"radius":_vm.getBombRadius(player),"stroke":false,"fillColor":"#FD7E14","fillOpacity":0.6},on:{"click":function($event){return _vm.$bus.handleGA('bomb_point','success')}}})}):_vm._e(),(
        _vm.$bus.mapControlsFlag.safezoneFlag &&
        Object.keys(_vm.$bus.safeZoneData).length
      )?[_c('l-circle',{attrs:{"lat-lng":_vm.getSafeZoneLatLngs,"radius":_vm.getSafeZoneRadius,"dashArray":"5","color":"#fff","fill":false}})]:_vm._e(),(
        _vm.$bus.mapControlsFlag.poisonFlag && Object.keys(_vm.$bus.safeZoneData).length
      )?[_c('l-polygon',{attrs:{"lat-lngs":_vm.getPoisonLatLngs,"stroke":false,"fillColor":"#5F22C1","fillOpacity":0.5}})]:_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.reviveFlag &&
        _vm.$bus.playerReviveData.length
      )?_vm._l((_vm.$bus.playerReviveData),function(player,index){return _c('l-marker',{key:("playerRevive" + index),attrs:{"lat-lng":_vm.getPlayerReviveLatLngs(player),"icon":_vm.getPointIconUrl('revive')}})}):_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.slotMachineFlag &&
        _vm.$bus.buyShopData.length
      )?_vm._l((_vm.$bus.buyShopData),function(player,index){return _c('l-marker',{key:("buyShop" + index),attrs:{"lat-lng":_vm.getBuyShopLatLngs(player),"icon":_vm.getPointIconUrl('slotMachine')}})}):_vm._e(),(
        _vm.$bus.getActiveMatchId &&
        _vm.$bus.mapControlsFlag.suppliesFlag &&
        _vm.$bus.hotzoneData.length
      )?_vm._l((_vm.$bus.hotzoneData),function(player,index){return _c('l-marker',{key:("hotzone" + index),attrs:{"lat-lng":_vm.getHotzoneLatLngs(player),"icon":_vm.getPointIconUrl('supplies')}})}):_vm._e(),[_c('l-marker',{key:"mapName",attrs:{"icon":_vm.getMapName,"lat-lng":_vm.mapNameLatLng}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }