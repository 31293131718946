<template>
  <div id="app">
    <home />
    <widget-loading v-show="$bus.loading" />
    <widget-toast v-show="$bus.toast.show" />
  </div>
</template>

<script>
import Home from './views/home'
import WidgetLoading from '@/shared/widget-loading'
import WidgetToast from '@/shared/widget-toast'
import { initGA } from '@/utils/assist'
export default {
  components: {
    Home,
    WidgetLoading,
    WidgetToast
  },
  methods: {
    onDocumentClickHandler() {
      this.$bus.activeLayer = -1
      this.$bus.activeIndex = -1
    }
  },
  created(){
    // console.log('before mounted')
    initGA()
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClickHandler)
    document.addEventListener('touchend', this.onDocumentClickHandler)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClickHandler)
    document.removeEventListener('touchend', this.onDocumentClickHandler)
  }
}
</script>
