import axios from 'axios'
import bus from '@/bus'

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '',
  timeout: 20000
  // withCredentials: true
})

// 请求集合，防止重复点击
const requestUrls = []
let requestFlag = ''
const removeRequestUrl = () => {
  // 移除队列中的该请求
  requestUrls.splice(requestUrls.indexOf(requestFlag), 1)
}

const reg = /.*csrftoken=([^;.]*).*$/

service.interceptors.request.use(
  req => {
    bus.setLoading(true)
    requestFlag = req.url + req.method
    if (requestUrls.indexOf(requestFlag) > -1) {
      req.cancelToken = new axios.CancelToken(cancel => {
        cancel('duplicate request')
      })
    } else {
      requestUrls.push(requestFlag)
      req.headers['X-CSRFToken'] = document.cookie.match(reg)
        ? document.cookie.match(reg)[1]
        : null
    }
    return req
  },
  error => {
    // 未考虑这种情况
    return Promise.reject(error)
    /**
     * 或将错误抛到业务层去处理
     * return Promise.resolve(error || 'Network connection error, please try again later' )
     */
  }
)

service.interceptors.response.use(
  res => {
    bus.setLoading(false)
    removeRequestUrl()
    const { status, msg } = res.data
    if (status === 'error') {
      bus.setToast({
        show: true,
        content: msg || 'NETWORK ERROR'
      })
      return Promise.reject(msg)
    }
    return res.data
  },
  error => {
    // 重复请求
    if (error.message && error.message === 'duplicate request') {
      return Promise.reject(error.message)
    }
    bus.setLoading(false)
    // 请求超时
    if (error.message && error.message.indexOf('timeout') > -1) {
      bus.setToast({ show: true, content: 'TIME OUT' })
      removeRequestUrl()
      return Promise.reject(error)
    }
    const code =
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : 'TOAST_NETWORK_ERROR'
    bus.setToast({ show: true, content: code })
    removeRequestUrl()
    return Promise.reject(error)
  }
)

const request = config => {
  if (config.method === 'get') {
    config.params = config.data
  }
  return service(config)
}

export default request
