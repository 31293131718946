import Vue from 'vue'
import { Slider, Switch, Select, Option } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'leaflet/dist/leaflet.css'
import App from './App.vue'
import bus from './bus'
import api from './api'
import './assets/scss/index.scss'

Vue.config.productionTip = false

Vue.prototype.$bus = bus
Vue.prototype.$api = api

Vue.use(Slider)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)

new Vue({
  render: h => h(App)
}).$mount('#app')
