<template>
  <div class="widget-teamcolor">
    <div class="widget-teamcolor-title">
      {{ getTitle }}
    </div>
    <div class="widget-teamcolor-wrapper">
      <widget-dot
        v-for="(item, index) in getList"
        :key="index"
        :text="getText(item)"
        v-show="getShow(item)"
        :color="onGetColor(item)"
      />
    </div>
  </div>
</template>

<script>
import WidgetDot from '../widget-dot'
import { getTeamColor, getMatchColor } from '@/utils/utils'

export default {
  components: {
    WidgetDot
  },
  computed: {
    getTitle() {
      return this.$bus.getIsMuti ? 'Match Color:' : 'Team Color:'
    },
    getList() {
      if (this.$bus.getIsMuti) {
        return this.$bus.matchIdList
      }
      const matchId = this.$bus.getActiveMatchId
      return this.$bus.teamList[matchId]
    },
    getText() {
      return (item) => {
        return this.$bus.getIsMuti ? `#${item.matchId}` : `#${item.team_id}`
      }
    },
    getShow() {
      return (item) => {
        if (this.$bus.getIsMuti) {
          return item.selected
        }
        return true
      }
    }
  },
  methods: {
    onGetColor(item) {
      if (this.$bus.getIsMuti) {
        const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
        const index = matchIdList.findIndex(
          (matchId) => matchId === item.matchId
        )
        return getMatchColor(index).color
      }
      return getTeamColor(item.team_id - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-teamcolor {
  @include flexac;
  position: relative;
  width: px2vw(600);
  padding: px2vh(6) 0;
  margin: 0 auto;

  @include float {
    // 700 / 950
    width: 86%;
  }

  .widget-teamcolor-title {
    @include flexac;
    width: px2vw(90);
    min-width: px2vw(90);
    font-size: 12px;
    font-weight: 700;
    color: $el-text--gray;

    @include lg {
      font-size: 10px;
    }
  }

  .widget-teamcolor-wrapper {
    @include flexac;
    flex-wrap: wrap;
    padding-left: px2vw(8);

    .widget-dot {
      margin: 0 px2vw(8);
    }

    & /deep/ .widget-dot__icon {
      width: 6px;
      height: 6px;
    }

    & /deep/ .widget-dot__text {
      color: $el-text--gray;
    }
  }
}
</style>
