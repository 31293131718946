<template>
  <label
    class="widget-checkbox"
    :class="disabled ? 'widget-checkbox--disabled' : ''"
    @click="onClick"
  >
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="currentValue"
      @change="onChange"
      class="widget-checkbox__input"
    />
    <i
      class="widget-checkbox__mock"
      :class="[
        disabled ? 'widget-checkbox__mock--disabled' : '',
        indeterminate ? 'widget-checkbox__mock--indeterminate' : ''
      ]"
    ></i>
    <span
      class="widget-checkbox__label"
      :class="disabled ? 'widget-checkbox__label--disabled' : ''"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false,
      require: true
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  methods: {
    onChange(e) {
      this.currentValue = e.target.checked
      if(this.label){
        this.$bus.handleGA(this.currentValue ? `Open ${this.label}` : `Close ${this.label}`, 'toggle')
      }
      this.$emit('input', this.currentValue)
      this.$emit('change')
    },
    onClick() {
      if (this.disabled) {
        this.$emit('click-label')
      }
    }
  },
  watch: {
    value(newVal) {
      this.currentValue = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-checkbox {
  @include flexac;
  position: relative;
  cursor: pointer;

  &.widget-checkbox--disabled {
    cursor: not-allowed;
  }

  .widget-checkbox__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .widget-checkbox__mock {
    /* display: inline-block; */
    position: relative;
    /* width: px2vw(18); */
    /* height: px2vh(18); */
    /* vertical-align: px2vh(-2); */
    width: 16px;
    height: 16px;
    /* vertical-align: -2px; */
    background: transparent;
    border: 1px solid $el-disabled;
    transition: background-color ease-out 0.3s;
    border-radius: 2px;

    &.widget-checkbox__mock--disabled {
      background-color: $el-disabled;
    }
  }

  .widget-checkbox__input:checked + .widget-checkbox__mock {
    background-color: $el-focus;
    border: 1px solid $el-focus;
  }
  .widget-checkbox__input:checked + .widget-checkbox__mock:after {
    content: '';
    position: absolute;
    top: px2vh(3);
    left: 0;
    width: 100%;
    height: 50%;
    border: 2px solid $el-home;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  .widget-checkbox__input + .widget-checkbox__mock--indeterminate {
    background: none;
  }

  .widget-checkbox__input + .widget-checkbox__mock--indeterminate:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: $el-focus;
    transform: rotate(0deg);
  }

  .widget-checkbox__label {
    /* display: inline-block; */
    padding-left: px2vw(10);
    font-size: 18px;
    color: $el-text;

    @include lg {
      font-size: 16px;
    }

    // @include md {
    //   font-size: 14px;
    // }

    // @include sm {
    //   font-size: 14px;
    // }

    &.widget-checkbox__label--disabled {
      color: $el-disabled;
    }
  }
}
</style>
