export const teamColorList = [
  '#000',
  '#fff',
  '#DC3545',
  '#FFC107',
  '#0D6EFD',
  '#6F42C1',
  '#D63384',
  '#FD7E14',
  '#198754',
  '#0DCAF0',
  '#2A3444',
  '#20C997'
]

export const matchColorList = {
  0: {
    color: '#0D6EFD',
    bgColor: 'rgba(13, 110, 253, 0.3)'
  },
  1: {
    color: '#F8F9FA',
    bgColor: 'rgba(248, 249, 250, 0.3)'
  },
  2: {
    color: '#FD7E14',
    bgColor: 'rgba(253, 126, 20, 0.3)'
  },
  3: {
    color: '#DC3545',
    bgColor: 'rgba(220, 53, 69, 0.3)'
  },
  4: {
    color: '#20C997',
    bgColor: 'rgba(32, 201, 151, 0.3)'
  },
  5: {
    color: '#FFC107',
    bgColor: 'rgba(255, 193, 7, 0.3)'
  }
}

export const mapScale = {
  1: 0.325,
  3: 0.36,
  4: 0.35,
  5: 0.33,
  14: 0.4,
  22: 0.5
}

// export const maxBounds = {
//   1: [
//     [
//       [1010 * mapScale[1], -380 * mapScale[1]],
//       [-790 * mapScale[1], 1420 * mapScale[1]]
//     ]
//   ],
//   3: [
//     [
//       [733 * mapScale[3], -987 * mapScale[3]],
//       [-1067 * mapScale[3], 813 * mapScale[3]]
//     ]
//   ],
//   4: [
//     [
//       [700 * mapScale[4], -700 * mapScale[4]],
//       [-700 * mapScale[4], 700 * mapScale[4]]
//     ]
//   ],
//   5: [
//     [
//       [900 * mapScale[5], -900 * mapScale[5]],
//       [-900 * mapScale[5], 900 * mapScale[5]]
//     ]
//   ]
// }
//
// export const mapConfig = {
//   1: {
//     url: require('../assets/images/map-1.jpg'),
//     bounds: [
//       [
//         [-790 * mapScale[1], -380 * mapScale[1]],
//         [1010 * mapScale[1], 1420 * mapScale[1]]
//       ]
//     ],
//     centerX: 520 * mapScale[1],
//     centerY: 110 * mapScale[1]
//   },
//   3: {
//     url: require('../assets/images/map-3.jpg'),
//     bounds: [
//       [
//         [-1067 * mapScale[3], -987 * mapScale[3]],
//         [733 * mapScale[3], 813 * mapScale[3]]
//       ]
//     ],
//     centerX: -167 * mapScale[3],
//     centerY: -87 * mapScale[3]
//   },
//   4: {
//     url: require('../assets/images/map-4.jpg'),
//     bounds: [
//       [
//         [-700 * mapScale[4], -700 * mapScale[4]],
//         [700 * mapScale[4], 700 * mapScale[4]]
//       ]
//     ],
//     centerX: 0,
//     centerY: 0
//   },
//   5: {
//     url: require('../assets/images/map-5.jpg'),
//     bounds: [
//       [
//         [-900 * mapScale[5], -900 * mapScale[5]],
//         [900 * mapScale[5], 900 * mapScale[5]]
//       ]
//     ],
//     centerX: 0,
//     centerY: 0
//   }
// }

export const mapOptions = {
  zoom: 0,
  maxZoom: 4,
  zoomControl: false,
  attributionControl: false,
  scrollWheelZoom: true
}

export const icons = [
  {
    id: 1,
    type: 'more',
    text: ''
  },
  {
    id: 2,
    type: 'zoom-out',
    text: ''
  },
  {
    id: 3,
    type: 'zoom-in',
    text: ''
  },
  {
    id: 4,
    type: 'max',
    text: ''
  }
]
// 优化点7
export const controls = [
  {
    id: 1,
    label: 'Movement',
    type: 'movement',
    hasSwitch: true,
    switchLabel: 'Merge Movement'
  },
  {
    id: 2,
    label: 'ActivePoint',
    type: 'activepoint'
  },
  {
    id: 3,
    label: 'DeadPoint',
    type: 'deadpoint'
  },
  {
    id: 4,
    label: 'LandingPoint',
    type: 'landingpoint'
  },
  {
    id: 5,
    label: 'Bomb',
    type: 'bomb'
  },
  {
    id: 6,
    label: 'Safe Zone',
    type: 'safezone'
  },
  {
    id: 7,
    label: 'Poison',
    type: 'poison'
  },
  {
    id: 8,
    label: 'Supplies',
    type: 'supplies'
  },
  {
    id: 9,
    label: 'Airline',
    type: 'airline'
  },
  {
    id: 10,
    label: 'Revive',
    type: 'revive'
  },
  {
    id: 11,
    label: 'Slot Machine',
    type: 'slotMachine'
  }
]
// 优化点4
export const playSpeed = [
  {
    value: 0,
    label: '5X'
  },
  {
    value: 1,
    label: '10X'
  },
  {
    value: 2,
    label: '20X'
  },
  {
    value: 3,
    label: '30X'
  }
]
