<template>
  <button
    class="widget-button"
    :class="mockDisabled ? 'widget-button--disabled' : ''"
    @click="onClick"
    :disabled="disabled"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mockDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click-button')
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-button {
  @include flexrc;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: $el-form;
  font-size: 18px;
  cursor: pointer;
  color: $el-text;
  transition: all ease-out 0.3s;

  @include lg {
    font-size: 16px;
  }

  &:hover {
    color: $el-focus;
  }

  &:active {
    background-color: $el-focus;
    color: $el-text;
  }

  &:disabled,
  &.widget-button--disabled {
    color: $el-disabled;
    background-color: $el-button-disabled;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
