<template>
  <div class="widget-team">
    <div
      class="team-info"
      :class="
        team.selected || team.userId.some((player) => player.selected)
          ? 'team-info--selected'
          : ''
      "
    >
      <widget-checkbox
        :indeterminate="
          !team.selected && team.userId.some((player) => player.selected)
        "
        :value="team.selected"
        @change="onTeamClick"
      />
      <div class="team-info-name">#{{ team.team_id +" "+ team.team_name}}</div>
      <div
        class="team-info-color"
        :class="
          onGetTeamColor(team.team_id - 1) === '#000'
            ? 'team-info-color--border'
            : ''
        "
        :style="{ backgroundColor: onGetTeamColor(team.team_id - 1) }"
      ></div>
      <div class="team-info-kills" @click="isCollapse">
        {{ $bus.onGetTeamKills(team.team_id) }} Kills
      </div>
      <i class="icon-down" :class="{up:isUp}" @click="isCollapse"></i>
    </div>
    <div class="team-members transitionCollapse" v-if="team.userId && team.userId.length" ref="isTeamsInfo">
      <div
        class="team-member"
        :class="player.selected ? 'team-member--selected' : ''"
        v-for="(player, index) in team.userId"
        :key="index"
        ref="teamMemberRef"
      >
        <transition name="slider">
          <widget-panel
            :userId="player.userId"
            :teamId="team.team_id"
            :layer="layer"
            v-if="
              $bus.getIsMuti &&
              $bus.activeIndex === index &&
              $bus.activeLayer === layer
            "
          />
        </transition>
        <widget-checkbox
          :value="player.selected"
          @change="onMemberClick(index)"
        />
        <div class="team-member-wrapper" @click.stop="onShowPanel(index, player.userId)">
          <div class="team-member-name">
            {{ $bus.onGetPlayerInfo(player.userId, team.team_id).nickname }}
          </div>
          <div class="team-member-info">
            {{
              $bus.onConvertSeconds(
                $bus.onGetPlayerSurvival(player.userId, team.team_id),
                'm[m] ss[s]'
              )
            }}
            | {{ $bus.onGetPlayerKills(player.userId, team.team_id) }} kills
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeamColor } from '@/utils/utils'
import WidgetCheckbox from '@/shared/widget-checkbox'
import WidgetPanel from '@/shared/widget-panel'

export default {
  props: {
    team: {
      type: Object,
      default: () => ({})
    },
    layer: {
      type: Number,
      default: 0
    },
    expandAll: {
      type: String,
      default: ''
    }
  },
  components: {
    WidgetCheckbox,
    WidgetPanel
  },
  data() {
    return {
      teamMembers: [],
      teamInfo: {},
      isUp: false
    }
  },
  watch: {
    expandAll(val) {
      if (val.includes('true')) {
        this.isUp = true
        this.$refs.isTeamsInfo.style.height = 150 + 'px'
      } else {
        this.isUp = false
        this.$refs.isTeamsInfo.style.height = 0 + 'px'
      }
    }
  },
  methods: {
    isCollapse() {
      const isCollapse = this.$refs.isTeamsInfo.offsetHeight === 0
      const height = this.$refs.teamMemberRef[0].clientHeight * this.team.userId.length
      if (isCollapse) {
        this.isUp = true
        this.$refs.isTeamsInfo.style.height = height + 'px'
      } else {
        this.isUp = false
        this.$refs.isTeamsInfo.style.height = 0 + 'px'
      }
      this.$emit('collapse', this.isUp)
    },
    onGetTeamColor(index) {
      return getTeamColor(index)
    },
    onMemberClick(memberIndex) {
      this.$emit('click-member', this.layer, memberIndex)
    },
    onTeamClick() {
      this.$emit('click-team', this.layer)
    },
    onShowPanel(index, id) {
      if (this.$bus.getIsMuti) {
        this.$bus.handleGA('show_player_muti_games_info', id)
      }
      this.$bus.activeIndex = index
      this.$bus.activeLayer = this.layer
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-team {
  width: 100%;
  margin-bottom: px2vh(10);
  cursor: pointer;
  border: 1px solid $el-form;
  overflow: hidden;

  .team-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: px2vh(60);
    padding: 0 px2vw(20) 0 px2vw(10);
    font-size: 18px;
    color: $el-text;
    transition: all ease-out 0.3s;

    @include lg {
      font-size: 16px;
    }

    &.team-info--selected {
      background-color: $el-form;
    }

    .team-info-color {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: red;

      &.team-info-color--border {
        border: 1px solid $el-em;
      }
    }

    .team-info-name {
      margin-right: px2vw(10);
      font-weight: 700;
    }

    .team-info-kills {
      margin-left: auto;
      font-weight: 400;
      padding-right: 12px;
    }
    .icon-down{
      background-image: url("../../assets/images/icon-down.png");
      width: 16px;
      height: 10px;
      background-size: 100% 100%;
      background-repeat: no-repeat no-repeat;
    }
    .up{
      transform: rotate(180deg);
    }
  }

  .team-members {
    width: 100%;
    height: 0px;
    transition: height 0.2s;

    .team-member {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0 px2vw(20) 0 calc(16px + 20 / 1920 * 100vw);
      font-size: 16px;
      font-weight: 400;
      color: $el-text--gray;
      transition: all ease-out 0.3s;

      @include lg {
        font-size: 14px;
      }

      &:hover {
        color: $el-focus;
      }

      &.team-member--selected {
        background-color: rgba(51, 65, 87, 0.5);
      }

      .team-member-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      .team-member-info {
        margin-left: auto;
      }
    }
  }
}
</style>
