<template>
  <div class="home-team" :class="fold ? 'home-team__fold' : ''">
    <div class="team-widget">
      <widget-input
        class="team-widget-input"
        placeholder="Match Id"
        v-model="$bus.matchId"
        @keyup="onAdd"
      />
      <widget-button
        class="team-widget-button"
        text="ADD"
        :mockDisabled="
          !$bus.matchId ||
          $bus.matchIdList.some((item) => item.matchId === $bus.matchId) ||
          $bus.matchIdList.length >= 6
        "
        @click-button="onAdd"
      />
    </div>
    <div class="team-matchid" v-if="$bus.matchIdList.length">
      <widget-matchid
        v-for="(item, index) in $bus.matchIdList"
        :key="index"
        :index="index"
        :selected="item.selected"
        :matchId="item.matchId"
        @click-widget-matchid="onSelectMatchid(index)"
        @click-icon-close="onCloseMatchid(index)"
      />
    </div>
    <div class="team-select" v-show="$bus.getActiveMatchId">
      <widget-checkbox
        label="All Teams"
        v-model="$bus.allTeamFlag"
        @change="onSelectAllTeams"
        :disabled="$bus.getIsMuti || !$bus.getActiveMatchId"
        @click-label="onClickLabel"
      />
      <div class="foldAll" @click.stop="changeCollapse">
        <p>{{ isCollapseUp ? 'Fold All' : 'Expand All' }}</p>
        <i class="icon-down" :class="{up: isCollapseUp}"></i>
      </div>
    </div>
    <div class="team-tip" v-if="$bus.getIsMuti">
      <!--优化点8-->
      <p>Team/Team member</p>
      <p>Average survival times | total kills</p>
    </div>
    <div class="team-list">
      <!-- <widget-panel
        v-if="
          panelShow && $bus.matchIdList.filter((item) => item.selected).length
        "
        :top="panelTop"
        ref="widgetPanel"
        :userId="panelUserId"
      /> -->
      <div
        class="team-list-wrapper"
        v-if="$bus.activeTeamList.length"
        @scroll="onScroll"
      >
        <div class="team-list-container">
          <widget-team
            v-for="(team, index) in $bus.activeTeamList"
            :key="index"
            :layer="index"
            :expandAll="expandAll"
            :team="team"
            @click-member="onSelectMember"
            @click-team="onSelectTeam"
            @collapse="isChangeCollapse($event, index, team)"
          />
        </div>
      </div>
      <widget-nodata v-else>
        <p>NO TEAM</p>
      </widget-nodata>
    </div>
    <div
      class="team-control"
      :class="fold ? 'team-control__fold' : 'team-control__unfold'"
      @click="onFold"
    ></div>
  </div>
</template>

<script>
import WidgetInput from '@/shared/widget-input'
import WidgetButton from '@/shared/widget-button'
import WidgetMatchid from '@/shared/widget-matchid'
import WidgetCheckbox from '@/shared/widget-checkbox'
import WidgetTeam from '@/shared/widget-team'
import WidgetNodata from '@/shared/widget-nodata'
import WidgetPanel from '@/shared/widget-panel'
import {getMapScale, getCenterX, getCenterY} from "@/utils/utils";

export default {
  components: {
    WidgetInput,
    WidgetButton,
    WidgetMatchid,
    WidgetCheckbox,
    WidgetTeam,
    WidgetNodata,
    WidgetPanel
  },
  data() {
    return {
      fold: false,
      panelShow: false,
      panelTop: 0,
      panelUserId: '',
      isCollapseUp: false,
      teamFLagList: [],
      expandAll: ''
    }
  },
  computed: {
    // teamFLagList() {
    //   return this.$bus.activeTeamList.map(() => {return false})
    // }
  },
  watch: {
    'teamFLagList': {
      handler(val) {
        if (val.some(item => item === true)) {
          this.isCollapseUp = true
        } else {
          this.isCollapseUp = false
        }
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    changeCollapse() {
      // this.isCollapseUp = !this.isCollapseUp
      // this.expandAll = !this.expandAll
      this.$bus.handleGA(!this.isCollapseUp ? 'expandAll' : 'foldAll',  'toggle')
      if (this.isCollapseUp) {
        this.isCollapseUp = !this.isCollapseUp
        this.expandAll = 'false' + new Date().getTime()
        this.teamFLagList = []
        this.$bus.activeTeamList.forEach(item => {
          if (item) {
            this.teamFLagList.push(false)
          }
        })
      } else {
        this.isCollapseUp = !this.isCollapseUp
        this.expandAll = 'true' + new Date().getTime()
        this.teamFLagList = []
        this.$bus.activeTeamList.forEach(item => {
          if (item) {
            this.teamFLagList.push(true)
          }
        })
      }
    },
    onClickLabel() {
      this.$bus.setToast({
        show: true,
        content: 'Mutiple matches can only view one team at the same time'
      })
    },
    isChangeCollapse(flag, index, teamInfo) {
      this.$bus.handleGA(flag ? `expand_${teamInfo.teamId}` : `fold_${teamInfo.teamId}`, 'toggle')
      if (this.teamFLagList.length !== this.$bus.activeTeamList.length) {
        this.$bus.activeTeamList.forEach(item => {
          if (item) {
            this.teamFLagList.push(false)
          }
        })
      }
      this.$set(this.teamFLagList, index, flag)
    },
    onScroll() {
      this.$bus.activeLayer = -1
      this.$bus.activeIndex = -1
    },
    onFold() {
      this.fold = !this.fold
    },
    onResize() {
      if (document.documentElement.clientWidth > 1200) {
        this.fold = false
      }
    },
    onCanCombine(oldMatchId, matchId) {
      if (!oldMatchId) {
        return true
      }
      // 地图的判断
      if (this.$bus.matchMeta[matchId].mapId !== this.$bus.mapId) {
        this.$bus.setToast({
          show: true,
          content:
            'Matches of different teams cannot be viewed at the same time'
        })
        return false
      }
      // 队员的判断
      let isSameMatchFlag = this.$bus.activeTeamList.every((item) => {
        const team = this.$bus.matchMeta[matchId].team_info.find(
          (ele) => ele.team_id === item.team_id
        )
        if (!team) {
          return false
        }
        let sameTeamMember = 0
        team.userId.forEach((userId) => {
          if (item.userId.map((x) => x.userId).includes(userId)) {
            sameTeamMember += 1
          }
        })
        return sameTeamMember >= 1
      })
      if (!isSameMatchFlag) {
        this.$bus.setToast({
          show: true,
          content:
            'Matches of different teams cannot be viewed at the same time'
        })
        return false
      }
      return true
    },
    onCombineTeamList(activeMatchId) {
      const selectedMatchIdList = this.$bus.matchIdList
        .filter((item) => item.selected)
        .map((x) => x.matchId)
        .filter((x) => x !== activeMatchId)
      this.$bus.activeTeamList = this.$bus.matchMeta[
        activeMatchId
      ].team_info.map((item, index) => ({
        ...item,
        selected: index === 0,
        userId: [
          ...new Set([
            ...item.userId,
            ...selectedMatchIdList
              .map(
                (matchId) =>
                  this.$bus.matchMeta[matchId].team_info.find(
                    (ele) => ele.team_id === item.team_id
                  ).userId
              )
              .flat()
          ])
        ].map((userId) => ({
          userId,
          selected: index === 0
        }))
      }))
    },
    onSelectMatchid(index) {
      const oldActiveMatchId = this.$bus.getActiveMatchId
      this.$bus.matchIdList.splice(index, 1, {
        ...this.$bus.matchIdList[index],
        selected: !this.$bus.matchIdList[index].selected
      })
      const activeMatchId = this.$bus.getActiveMatchId
      // 取消选择
      if (!this.$bus.matchIdList[index].selected) {
        this.$bus.handleGA('unselect_match',this.$bus.matchIdList[index].matchId)
        this.onResetFlagStatus()
        this.onResetDataStatus()
        // 0场
        if (!activeMatchId) {
          this.$bus.allTeamFlag = false
          this.$bus.activeTeamList = []
          return
        }
        // 1场
        if (!this.$bus.getIsMuti) {
          this.$bus.allTeamFlag = true
          this.$bus.activeTeamList = this.$bus.matchMeta[
            activeMatchId
          ].team_info.map((item) => ({
            ...item,
            selected: true,
            userId: item.userId.map((userId) => ({
              userId,
              selected: true
            }))
          }))
        } else {
          this.$bus.allTeamFlag = false
          this.onCombineTeamList(activeMatchId)
        }
      } else {
        this.$bus.handleGA('select_match',this.$bus.matchIdList[index].matchId)
        // 若可以合并
        if (
          this.onCanCombine(
            oldActiveMatchId,
            this.$bus.matchIdList[index].matchId
          )
        ) {
          this.onResetFlagStatus()
          this.onResetDataStatus()
          // 多场
          if (this.$bus.getIsMuti) {
            this.$bus.allTeamFlag = false
            this.onCombineTeamList(activeMatchId)
          } else {
            this.$bus.mapId = this.$bus.matchMeta[activeMatchId].mapId
            this.$bus.allTeamFlag = true
            this.$bus.activeTeamList = this.$bus.matchMeta[
              activeMatchId
            ].team_info.map((item) => ({
              ...item,
              selected: true,
              userId: item.userId.map((userId) => ({
                userId,
                selected: true
              }))
            }))
          }
        } else {
          this.$bus.matchIdList.splice(index, 1, {
            ...this.$bus.matchIdList[index],
            selected: !this.$bus.matchIdList[index].selected
          })
        }
      }
    },
    onCloseMatchid(index) {
      this.$bus.handleGA('delete_match',this.$bus.matchIdList[index].matchId)
      this.$bus.matchIdList.splice(index, 1)
      const activeMatchId = this.$bus.getActiveMatchId
      // 0场
      if (!activeMatchId) {
        this.$bus.allTeamFlag = false
        this.$bus.activeTeamList = []
        return
      }
      // 1场
      if (!this.$bus.getIsMuti) {
        this.$bus.allTeamFlag = true
        this.$bus.activeTeamList = this.$bus.matchMeta[
          activeMatchId
        ].team_info.map((item) => ({
          ...item,
          selected: true,
          userId: item.userId.map((userId) => ({
            userId,
            selected: true
          }))
        }))
      } else {
        const selectedMatchIdList = this.$bus.matchIdList
          .filter((item) => item.selected)
          .map((x) => x.matchId)
          .filter((x) => x !== activeMatchId)
        this.$bus.activeTeamList = this.$bus.matchMeta[
          activeMatchId
        ].team_info.map((item, index) => ({
          ...item,
          selected: index === 0,
          userId: [
            ...new Set([
              ...item.userId,
              ...selectedMatchIdList
                .map(
                  (matchId) =>
                    this.$bus.matchMeta[matchId].team_info.find(
                      (ele) => ele.team_id === item.team_id
                    ).userId
                )
                .flat()
            ])
          ].map((userId) => ({
            userId,
            selected: index === 0
          }))
        }))
      }
      this.onResetFlagStatus()
      this.onResetDataStatus()
    },
    onSelectAllTeams() {
      // 多场或0场
      if (this.$bus.getIsMuti && !this.$bus.getActiveMatchId) {
        return
      }
      this.$bus.activeTeamList = this.$bus.activeTeamList.map((item) => ({
        ...item,
        selected: this.$bus.allTeamFlag,
        userId: item.userId.map((ele) => ({
          ...ele,
          selected: this.$bus.allTeamFlag
        }))
      }))
    },
    onSelectMember(teamIndex, memberIndex) {
      const matchId = this.$bus.getActiveMatchId
      // 添加人员埋点
      let member = this.$bus.activeTeamList[teamIndex].userId[memberIndex]
      this.$bus.handleGA(member.selected?'unselect_member':'select_member',member.userId)
      // 多场

      if (this.$bus.getIsMuti) {
        const currentIndex = this.$bus.activeTeamList.findIndex(
          (item) => item.selected || item.userId.some((ele) => ele.selected)
        )
        if (teamIndex !== currentIndex) {
          this.$bus.activeTeamList = this.$bus.activeTeamList.map((item) => ({
            ...item,
            selected: false,
            userId: item.userId.map((ele) => ({
              ...ele,
              selected: false
            }))
          }))
        }
      }
      this.$bus.activeTeamList.splice(teamIndex, 1, {
        ...this.$bus.activeTeamList[teamIndex],
        userId: this.$bus.activeTeamList[teamIndex].userId.map(
          (item, index) => ({
            ...item,
            selected:
              memberIndex === index
                ? !this.$bus.activeTeamList[teamIndex].userId[index].selected
                : this.$bus.activeTeamList[teamIndex].userId[index].selected
          })
        )
      })
      this.$bus.activeTeamList.splice(teamIndex, 1, {
        ...this.$bus.activeTeamList[teamIndex],
        selected: this.$bus.activeTeamList[teamIndex].userId.every(
          (item) => item.selected
        )
      })

      // 单场
      if (!this.$bus.getIsMuti) {
        this.onSetAllTeamFlag()
      }
    },
    onSelectTeam(teamIndex) {
      // 多场
      if (this.$bus.getIsMuti) {
        const currentIndex = this.$bus.activeTeamList.findIndex(
          (item) => item.selected || item.userId.some((ele) => ele.selected)
        )
        if (teamIndex !== currentIndex) {
          this.$bus.activeTeamList = this.$bus.activeTeamList.map((item) => ({
            ...item,
            selected: false,
            userId: item.userId.map((ele) => ({
              ...ele,
              selected: false
            }))
          }))
        }
        this.$bus.handleGA(this.$bus.activeTeamList[teamIndex].selected?'unselect_team':'select_team',this.$bus.activeTeamList[teamIndex].team_id)
        this.$bus.activeTeamList.splice(teamIndex, 1, {
          ...this.$bus.activeTeamList[teamIndex],
          selected: !this.$bus.activeTeamList[teamIndex].selected,
          userId: this.$bus.activeTeamList[teamIndex].userId.map((ele) => ({
            ...ele,
            selected: !this.$bus.activeTeamList[teamIndex].selected
          }))
        })
      } else {
        this.$bus.handleGA(this.$bus.activeTeamList[teamIndex].selected?'unselect_team':'select_team',this.$bus.activeTeamList[teamIndex].team_id)
        this.$bus.activeTeamList.splice(teamIndex, 1, {
          ...this.$bus.activeTeamList[teamIndex],
          selected: !this.$bus.activeTeamList[teamIndex].selected,
          userId: this.$bus.activeTeamList[teamIndex].userId.map((ele) => ({
            ...ele,
            selected: !this.$bus.activeTeamList[teamIndex].selected
          }))
        })
        this.onSetAllTeamFlag()
      }
    },
    onSetAllTeamFlag() {
      const activeMatchId = this.$bus.getActiveMatchId
      // 多场或0场
      if (this.$bus.getIsMuti || !activeMatchId) {
        return
      }
      // 单场
      if (this.$bus.activeTeamList.every((item) => item.selected)) {
        this.$bus.allTeamFlag = true
      } else {
        this.$bus.allTeamFlag = false
      }
    },
    onResetFlagStatus() {
      this.$bus.mapControlsFlag = {
        movementFlag: true,
        activepointFlag: false,
        deadpointFlag: true,
        landingpointFlag: true,
        bombFlag:  false,
        safezoneFlag: false,
        poisonFlag: !this.$bus.getIsMuti,
        suppliesFlag: false,
        airlineFlag: false,
        reviveFlag: false,
        slotMachineFlag: false
      }
    },
    onResetDataStatus() {
      this.$bus.movementData = []
      this.$bus.diePointData = []
      this.$bus.landingPointData = []
      this.$bus.activePointData = []
      this.$bus.bombData = []
      this.$bus.safeZoneData = []
      this.$bus.poisonData = []
      this.$bus.sliderValue = 0
      this.$bus.sliderMin = ''
      this.$bus.sliderSec = ''
      this.$bus.airlineData = []
      this.$bus.playerReviveData = []
      this.$bus.buyShopData = []
      this.$bus.hotZoneData = []
    },
    onAdd() {
      if (!this.$bus.matchId) {
        return
      }
      if (this.$bus.matchIdList.length >= 6) {
        this.$bus.setToast({ show: true, content: 'Add up to 6 matches' })
        return
      }
      if (
        this.$bus.matchIdList.some((item) => item.matchId === this.$bus.matchId)
      ) {
        return
      }
      this.onGetMatchInfo(this.$bus.matchId)
      this.$bus.handleGA('add_match_finish',this.$bus.matchId)
    },
    async onGetMatchInfo(matchId) {
      try {
        const activeMatchId = this.$bus.getActiveMatchId
        const result = await this.$api.match({
          match_id: matchId,
          team_info: activeMatchId
            ? this.$bus.matchMeta[activeMatchId].team_info.map((x) => ({
                team_id: x.team_id,
                user_id: x.userId,
                team_name: x.team_name
              }))
            : [],
          map_id: this.$bus.mapId !== 0 ? this.$bus.mapId : -1,
          map_pk: this.$bus.mapPK === 0 ? undefined : this.$bus.mapPK
        })
        const {
          match_meta,
          account_meta,
          players_pos,
          players_die,
          players_land,
          safe_zone,
          bomb_zone,
          airline,
          player_revive,
          player_buy_shop,
          hotzone,
          skydiving,
          map
        } = result.data
        // 判断是否有新数据
        this.$bus.airlineDataFlag = airline.length !== 0
        this.$bus.slotMachineDataFlag = player_buy_shop.length !== 0
        this.$bus.reviveDataFlag = player_revive.length !== 0
        this.$bus.suppliesDataFlag = hotzone.length !== 0
        // 配置地图数据
        this.$set(this.$bus.mapVertexConfig, map.gs_id, map)
        const mapBoundsInfo = [
          [
            [map.bottom_right_x * getMapScale(map.gs_id), map.bottom_right_y * getMapScale(map.gs_id)],
            [map.top_left_x * getMapScale(map.gs_id), map.top_left_y * getMapScale(map.gs_id)]
          ]
        ]
        const mapConfigInfo = {
          url: map.img,
          bounds: [
            [
              [map.bottom_left_x * getMapScale(map.gs_id), map.bottom_left_y * getMapScale(map.gs_id)],
              [map.top_right_x * getMapScale(map.gs_id), map.top_right_y * getMapScale(map.gs_id)]
            ]
          ],
          centerX: map.gs_id === 1 ? getCenterY(map.bottom_left_y, map.top_right_y) * getMapScale(map.gs_id) : getCenterX(map.bottom_left_x, map.top_right_x) * getMapScale(map.gs_id),
          centerY: map.gs_id === 1 ? getCenterX(map.bottom_left_x, map.top_right_x) * getMapScale(map.gs_id) : getCenterY(map.bottom_left_y, map.top_right_y) * getMapScale(map.gs_id)
        }
        this.$bus.mapId = match_meta.mapId
        this.$bus.mapPK = map.pk
        this.$set(this.$bus.maxBounds, match_meta.mapId, mapBoundsInfo)
        this.$set(this.$bus.mapConfig, match_meta.mapId, mapConfigInfo)
        this.onResetFlagStatus()
        this.onResetDataStatus()
        this.$bus.airlineData = airline
        this.$set(this.$bus.accountMeta, matchId, account_meta)
        this.$set(this.$bus.matchMeta, matchId, match_meta)
        // 轨迹过滤掉和航线重合的部分，如果无跳伞点的数据，则保持不变
        this.$set(
          this.$bus.playersPos,
          matchId,
          players_pos.map((item) => ({
            ...item,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(item.userId)
            ).team_id
          })).filter(info => {
            let point = {}
            if (skydiving.length === 0) {
              return info
            }
            skydiving.forEach(x => {
              if(info.userId === x.userId) {
                point = x
              }
            })
            return info.time >= point.time
          })
        )
        skydiving.forEach(info => {
          const obj = {
            ...info,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(info.userId)
            ).team_id
          }
          this.$bus.playersPos[matchId].push(obj)
        })
        this.$set(
          this.$bus.playersDie,
          matchId,
          players_die.map((item) => ({
            ...item,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(item.userId)
            ).team_id,
            matchId
          }))
        )
        this.$set(
          this.$bus.playerRevive,
          matchId,
          player_revive.map((item) => ({
            ...item,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(item.userId)
            ).team_id,
            matchId
          }))
        )
        this.$set(
          this.$bus.buyShop,
          matchId,
          player_buy_shop.map((item) => ({
            ...item,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(item.userId)
            ).team_id,
            matchId
          }))
        )
        this.$set(this.$bus.hotZone, matchId, hotzone)
        this.$set(
          this.$bus.playersLand,
          matchId,
          players_land.map((item) => ({
            ...item,
            teamId: match_meta.team_info.find((x) =>
              x.userId.includes(item.userId)
            ).team_id,
            matchId
          }))
        )
        this.$set(this.$bus.safeZone, matchId, safe_zone)
        this.$set(
          this.$bus.bombZone,
          matchId,
          bomb_zone.map((item) => ({
            ...item,
            posZ: item.pos_z,
            posX: item.pos_x
          }))
        )
        this.$bus.matchIdList.push({
          matchId,
          selected: true
        })
        this.$set(this.$bus.teamList, matchId, match_meta.team_info)
        this.$bus.matchId = ''
        // 多场
        if (this.$bus.getIsMuti) {
          this.$bus.allTeamFlag = false
          // 是否需要重新合并？
          this.$bus.activeTeamList = this.$bus.teamList[
            this.$bus.getActiveMatchId
          ].map((item, index) => ({
            ...item,
            selected: index === 0,
            userId: [
              ...new Set([
                ...item.userId,
                ...match_meta.team_info.find(
                  (team) => team.team_id === item.team_id
                ).userId
              ])
            ].map((userId) => ({
              userId,
              selected: index === 0
            }))
          }))
        } else {
          this.$bus.allTeamFlag = true
          this.$bus.activeTeamList = this.$bus.teamList[
            this.$bus.getActiveMatchId
          ].map((item) => ({
            ...item,
            selected: true,
            userId: item.userId.map((userId) => ({
              userId,
              selected: true
            }))
          }))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-team {
  display: flex;
  flex-direction: column;
  position: relative;
  width: px2vw(600);
  height: 100%;
  padding: px2vh(30) 0 px2vh(30) px2vw(30);
  background-color: $el-home;
  border-radius: 10px;

  &.home-team__fold {
    width: 0%;
  }

  @include float {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    box-shadow: -2px 0 6px $el-icon;
  }

  .team-widget {
    display: flex;
    justify-content: space-between;
    width: px2vw(540);
    height: px2vh(40);

    .team-widget-input {
      width: px2vw(410);
      height: 100%;
    }

    .team-widget-button {
      width: px2vw(120);
      height: 100%;
    }
  }

  .team-matchid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: px2vw(540);
    height: auto;
  }

  .team-select {
    @include flexac;
    justify-content: space-between;
    position: relative;
    width: px2vw(540);
    height: px2vh(60);
    padding-left: px2vw(10);
    padding-right: px2vw(20);

    .foldAll{
      @include flexrc;
      height: 100%;
      cursor: pointer;

      .icon-down{
        background-image: url("../../../assets/images/icon-down.png");
        width: 16px;
        height: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat no-repeat;
        margin-left: 12px;
      }

      .up{
        transform: rotate(180deg);
      }
    }
  }

  .team-tip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: px2vw(540);
    height: px2vh(50);
    padding-left: px2vw(10);
    padding-right: px2vw(20);
    color: $el-text-tips;
    font-size: 16px;
    //text-align: right;
    /* 单行省略号 */
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;

    @include lg {
      font-size: 14px;
    }
  }

  .team-list {
    position: relative;
    width: px2vw(556);
    flex: 1;

    .team-list-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .team-list-container {
      width: px2vw(540);
    }
  }

  .team-control {
    display: none;
    position: absolute;
    top: 2px;
    left: -42px;
    width: 40px;
    height: 32px;

    &.team-control__fold {
      background: url('../../../assets/images/icon-fold.png') top center / 100%
        100% no-repeat;
    }

    &.team-control__unfold {
      background: url('../../../assets/images/icon-unfold.png') top center /
        100% 100% no-repeat;
    }

    @include float {
      display: block;
    }
  }
}
</style>
