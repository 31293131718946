import anime from 'animejs/lib/anime.es.js'

class VueAnime {
  sliderY(el, top, fn, duration = 300) {
    anime({
      targets: el,
      opacity: [0, 1],
      translateY: [top, '0%'],
      duration,
      complete() {
        if (typeof fn === 'function') fn()
      },
      easing: 'linear'
    })
  }

  opacity(el, duration = 800) {
    anime({
      targets: el,
      opacity: [0, 1],
      easing: 'ease-out',
      duration
    })
  }
}

export default new VueAnime()
