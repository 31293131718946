<template>
  <div class="widget-input">
    <input
      type="text"
      class="widget-input__inner"
      :value="currentValue"
      @input="onInput"
      @change="onChange"
      @keyup.enter="onKeyup"
      :placeholder="placeholder"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  methods: {
    onInput(e) {
      this.currentValue = e.target.value
      this.$emit('input', this.currentValue)
    },
    onKeyup() {
      this.$emit('keyup')
    },
    onChange() {
      this.$emit('change')
    }
  },
  watch: {
    value(newVal) {
      this.currentValue = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-input {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .widget-input__inner {
    width: 100%;
    height: 100%;
    padding: 0 px2vw(20);
    background-color: $el-home;
    border: 1px solid $el-form;
    border-radius: 5px;
    font-size: 16px;
    color: $el-text;

    @include lg {
      font-size: 14px;
    }

    // @include md {
    //   font-size: 12px;
    // }

    // @include sm {
    //   font-size: 12px;
    // }

    &:focus {
      border: 1px solid $el-focus;
      transition: border ease-out 0.3s;
    }

    &::placeholder {
      color: $el-disabled;
    }
  }
}
</style>
