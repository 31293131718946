<template>
  <div
    class="home-map"
    :class="$bus.fullscreenFlag ? 'home-map__fullscreen' : ''"
    ref="homeMapRef"
  >
    <div class="home-map-wrapper" v-if="$bus.mapId && $bus.getActiveMatchId">
      <div
        class="map-show"
        :class="$bus.fullscreenFlag ? 'map-show__fullscreen' : ''"
      >
        <div
          class="map-show-container"
          :class="$bus.fullscreenFlag ? 'map-show-container__fullscreen' : ''"
        >
          <widget-map ref="map" :key="$bus.mapId" />
        </div>
        <div class="map-show-control">
          <div class="show-control-wrapper">
            <div class="show-control-slider" ref="sliderRef">
              <el-slider
                v-model="$bus.sliderValue"
                :show-tooltip="false"
                :min="0"
                :max="$bus.getSliderMax"
                :step="30"
                @input="onInputSliderValue"
                @change="onChangeSliderValue"
              >
              </el-slider>
              <template
                v-if="getMarkBomb && getMarkBomb.length && !$bus.getIsMuti"
              >
                <div
                  class="
                    show-control-slider-mark show-control-slider-mark__bomb
                  "
                  v-for="(bomb, index) in getMarkBomb"
                  :key="`bomb${index}`"
                  :style="getMarkBombStyle(bomb)"
                ></div>
              </template>
              <template
                v-if="
                  getMarkSafezone && getMarkSafezone.length && !$bus.getIsMuti
                "
              >
                <div
                  class="
                    show-control-slider-mark show-control-slider-mark__safezone
                  "
                  v-for="(safezone, index) in getMarkSafezone"
                  :key="`safe${index}`"
                  :style="getMarkSafezoneStyle(safezone)"
                ></div>
              </template>
              <div class="show-control-survival">
                00:{{ ~~$bus.onConvertSeconds($bus.getSliderMax, 'm') }}:{{
                  ~~$bus.onConvertSeconds($bus.getSliderMax, 'ss')
                }}
              </div>
            </div>
            <div class="show-control-group">
              <div class="show-control-form">
                <widget-button
                  class="show-control__button--reduce"
                  text="-30s"
                  @click-button="onReduceSec"
                  :disabled="$bus.sliderValue <= 0"
                />
                <widget-input
                  class="show-control__input--min"
                  v-model="$bus.sliderMin"
                  @change="onChangeSliderMin"
                  @keyup="onChangeSliderMin"
                >
                  <span class="show-control__input--unit">m</span>
                </widget-input>
                <widget-input
                  class="show-control__input--sec"
                  v-model="$bus.sliderSec"
                  @change="onChangeSliderSec"
                  @keyup="onChangeSliderSec"
                >
                  <span class="show-control__input--unit">s</span>
                </widget-input>
                <widget-button
                  class="show-control__button--increase"
                  text="+30s"
                  :disabled="$bus.sliderValue >= $bus.getSliderMax"
                  @click-button="onIncreaseSec"
                />
              </div>
              <div class="show-control-mark" v-if="!$bus.getIsMuti">
                <widget-dot text="Bomb" color="#fd7e14" />
                <widget-dot text="Safe Zone" />
              </div>
              <div class="playControl">
                <div class="selectPlaySpeed">
                  <el-select size="small" :popper-append-to-body="false" @visible-change="hasVisible" @change="changeSpeed" v-model="selectedSpeed">
                    <el-option
                      v-for="item in playSpeed"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="selectPlayButton" @click="playMovement">
                  <i class="controlButton" :class="[isPlay ? 'stopIcon' : 'playIcon']"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="show-control-hint">
            <widget-teamcolor />
          </div>
        </div>
      </div>
      <div
        class="map-control"
        :class="$bus.fullscreenFlag ? 'map-control__fullscreen' : ''"
      >
        <div class="map-control-icons">
          <widget-icon
            v-for="icon in icons"
            :key="icon.id"
            :type="
              icon.type !== 'max'
                ? icon.type
                : $bus.fullscreenFlag
                ? 'min'
                : 'max'
            "
            :text="icon.text"
            :class="`control-icon__${icon.type}`"
            @click-icon="
              onControlIcon(
                icon.type !== 'max'
                  ? icon.type
                  : $bus.fullscreenFlag
                  ? 'min'
                  : 'max'
              )
            "
            v-show="icon.type !== 'more' ? true : $bus.fullscreenFlag"
            :disabled="getZoomDisabled(icon.type)"
          />
        </div>
        <div
          class="map-control-list"
          :class="$bus.fullscreenFlag ? 'map-control-list__fullscreen' : ''"
          v-show="!$bus.fullscreenFlag || moreFlag"
        >
          <span
            class="map-control-triangle"
            v-show="$bus.fullscreenFlag"
          ></span>
          <div
            class="widget-control"
            v-for="control in controls"
            :key="control.id"
            :class="[control.hasSwitch ? 'flexColumn' : '']"
          >
            <div class="checkboxPart">
              <widget-checkbox
                :label="control.label"
                v-model="$bus.mapControlsFlag[`${control.type}Flag`]"
                :disabled="getControlDisabled(control.type)"
                @click-label="onClickLabel"
              />
              <div
                class="widget-control-mark"
                :class="`widget-control-mark__${control.type}`"
              ></div>
            </div>
            <div v-if="control.hasSwitch" class="switchPart">
              <el-switch class="elSwitch" @change="changeMergeMovement" :disabled="!$bus.mapControlsFlag[`${control.type}Flag`]" v-model="hasMergeMovement">
              </el-switch>
              <span>{{ control.switchLabel }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <widget-nodata v-else>
      <p>NO MAP</p>
    </widget-nodata>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import WidgetMap from '@/shared/widget-map'
import WidgetIcon from '@/shared/widget-icon'
import WidgetNodata from '@/shared/widget-nodata'
import WidgetButton from '@/shared/widget-button'
import WidgetInput from '@/shared/widget-input'
import WidgetTeamcolor from '@/shared/widget-teamcolor'
import WidgetDot from '@/shared/widget-dot'
import WidgetCheckbox from '@/shared/widget-checkbox'
import { getControls, getIcons, getPlaySpeed } from '@/utils/utils'
import ActiveWorker from '@/worker/active.worker'

export default {
  components: {
    WidgetMap,
    WidgetIcon,
    WidgetNodata,
    WidgetButton,
    WidgetInput,
    WidgetTeamcolor,
    WidgetDot,
    WidgetCheckbox
  },
  data() {
    return {
      moreFlag: true,
      hasMergeMovement: false,
      selectedSpeed: 1,
      isPlay: false,
      playTimer: undefined
    }
  },
  created() {
    this.icons = getIcons()
    this.playSpeed = getPlaySpeed()
    this.controls = getControls()
  },
  mounted() {
    screenfull.on('change', this.onScreenChange)
    // worker线程
    this.activeWorker = new ActiveWorker()
    this.activeWorker.onmessage = (e) => {
      this.onSetActivepointData(e.data)
    }
    if (this.$bus.mapControlsFlag.movementFlag) {
      this.hasMergeMovement = true
    }
  },
  beforeDestroy() {
    this.activeWorker.terminate()
    screenfull.off('change', this.onScreenChange)
  },
  computed: {
    getZoomDisabled() {
      return (type) => {
        if (type === 'zoom-out') {
          return this.$bus.mapZoom <= 0
        }
        if (type === 'zoom-in') {
          return this.$bus.mapZoom >= 4
        }
        return false
      }
    },
    getControlDisabled() {
      return (type) => {
        if (type === 'bomb' || type === 'safezone' || type === 'poison') {
          return this.$bus.getIsMuti
        }
        // 新的数据需要判断没数据时disabled
        if (type === 'supplies' || type === 'airline' || type === 'revive' || type === 'slotMachine') {
          return this.$bus.getIsMuti || !this.$bus[`${type}DataFlag`]
        }
        return false
      }
    },
    getMarkBomb() {
      const matchId = this.$bus.getActiveMatchId
      return this.$bus.bombZone[matchId]
    },
    getMarkBombStyle() {
      return (bomb) => {
        const sliderMax = this.$bus.getSliderMax
        return {
          left: `${(bomb.start_time / sliderMax) * 100}%`,
          width: `${((bomb.end_time - bomb.start_time) / sliderMax) * 100}%`
        }
      }
    },
    getMarkSafezone() {
      const matchId = this.$bus.getActiveMatchId
      return this.$bus.safeZone[matchId].filter(
        (safezone) =>
          safezone.time_span_type === 2 &&
          safezone.end_time / 1000 <= this.$bus.getSliderMax
      )
    },
    getMarkSafezoneStyle() {
      return (safezone) => {
        const sliderMax = this.$bus.getSliderMax
        return {
          left: `${(safezone.end_time / 1000 / sliderMax) * 100}%`
          // width: `${
          //   ((safezone.end_time / 1000 - safezone.start_time / 1000) /
          //     sliderMax) *
          //   100
          // }%`
        }
      }
    },
    getSpeed() {
      // 优化点4
      let milliseconds = 1
      switch (this.selectedSpeed) {
        case 0:
          milliseconds = 5
          break;
        case 1:
          milliseconds = 10
          break;
        case 2:
          milliseconds = 20
          break;
        case 3:
          milliseconds = 30
          break;
        default:
          milliseconds = 1
          break;
      }
      return milliseconds
    }
  },
  methods: {
    hasVisible(flag) {
      if (flag) {
        const domSelect = document.getElementsByClassName('el-select-dropdown el-popper')[0]
        if (domSelect) {
          this.$nextTick(() =>{
            if (this.$bus.fullscreenFlag) {
              domSelect.style.top = '-105px'
              domSelect.style.left = 'unset'
            }else {
              domSelect.style.top = '-160px'
              domSelect.style.left = 'unset'
            }
          })
        }
      }
    },
    playMovement() {
      this.$bus.handleGA('autoPlay', this.isPlay ? 'play' : 'stop')
      if(!this.isPlay) {
        this.playTimer = setInterval(() => {
          if (this.$bus.sliderValue <= this.$bus.getSliderMax) {
            this.$bus.sliderValue += this.getSpeed
          } else if (this.$bus.sliderValue + this.getSpeed > this.$bus.getSliderMax) {
            this.$bus.sliderValue = this.$bus.getSliderMax
            clearInterval(this.playTimer)
            this.isPlay = !this.isPlay
          }
          this.$bus.sliderSec = `${this.$bus.onConvertSeconds(
            this.$bus.sliderValue,
            's.S'
          )}`
          this.$bus.sliderMin = `${this.$bus.onConvertSeconds(
            this.$bus.sliderValue,
            'm'
          )}`
          this.onSetMapData()
        }, 1000)
      } else {
        clearInterval(this.playTimer)
      }
      this.isPlay = !this.isPlay
    },
    handleMarkStyleGa(bomb){
      console.log(bomb)
    },
    onClickLabel() {
      if(this.$bus.getIsMuti) {
        this.$bus.setToast({
          show: true,
          content: 'Not displayed when viewing multiple matches'
        })
      } else {
        this.$bus.setToast({
          show: true,
          content: 'No such data'
        })
      }

    },
    onScreenChange() {
      this.$bus.fullscreenFlag = screenfull.isFullscreen
    },
    onControlIcon(type) {
      if (type === 'max' || type === 'min') {
        if (!screenfull.isEnabled) {
          return
        }
        if (type === 'max') {
          this.moreFlag = true
        }
        this.$bus.handleGA(type=='max' ? 'fullscreen_open' : 'fullscreen_close', 'click')
        screenfull.toggle(this.$refs.homeMapRef)
      } else if (type === 'more') {
        this.$bus.handleGA(this.moreFlag ? 'fullscreen_close_more' : 'fullscreen_open_more', 'click')
        this.moreFlag = !this.moreFlag
      } else if (type === 'zoom-out') {
        this.$refs.map.onZoomOut()
      } else if (type === 'zoom-in') {
        this.$refs.map.onZoomIn()
      }
    },
    onReduceSec() {
      this.$bus.handleGA('reduce_30_button','click')
      if (this.$bus.sliderValue >= 30) {
        this.$bus.sliderValue -= 30
      } else {
        this.$bus.sliderValue = 0
      }
      this.$bus.sliderSec = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'ss'
      )}`
      this.$bus.sliderMin = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'm'
      )}`
      this.onSetMapData()
    },
    onIncreaseSec() {
      this.$bus.handleGA('increase_30_button','click')
      if (this.$bus.sliderValue <= this.$bus.getSliderMax - 30) {
        this.$bus.sliderValue += 30
      } else if (this.$bus.sliderValue + 30 > this.$bus.getSliderMax) {
        this.$bus.sliderValue = this.$bus.getSliderMax
      }
      this.$bus.sliderSec = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'ss'
      )}`
      this.$bus.sliderMin = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'm'
      )}`
      this.onSetMapData()
    },
    onInputSliderValue() {
      // this.onSetMapData()
      this.onSetMovementData()
      this.onSetDiepointData()
      this.onSetLandingpointData()
      this.onSetBombData()
      this.onSetSafeZoneData()
      this.onSetPlayerReviveData()
      this.onSetBuyShopData()
      this.onSetHotZoneData()
    },
    // 新增，判断是否拖动条的位置在爆炸或者安全范围的区间内并埋点的逻辑
    slideGA(){
      let val = this.$bus.sliderValue
      this.$bus.handleGA('timeline_seconds',val)
      const bombs = this.getMarkBomb
      const safezones = this.getMarkSafezone
      bombs.forEach((bomb)=>{
        if(val>=bomb.start_time&&val<=bomb.end_time){
          this.$bus.handleGA('timeline_bombpoint','success')
        }
      })
      safezones.forEach((safezone)=>{
        if(val>=(safezone.start_time/1000)&&val<=(safezone.end_time/1000)){
          this.$bus.handleGA('timeline_safezone','success')
        }
      })
    },
    onChangeSliderValue() {
      this.slideGA()
      this.$bus.sliderSec = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'ss'
      )}`
      this.$bus.sliderMin = `${this.$bus.onConvertSeconds(
        this.$bus.sliderValue,
        'm'
      )}`
      // this.onSetMapData()
      this.onSendActivePointMessage()
    },
    onChangeSliderSec() {
      this.$bus.handleGA('timeline_input_second','success')
      // 还原之前的值
      if (!/^-{0,}\d{1,}$/.test(this.$bus.sliderSec)) {
        this.$bus.sliderSec = ''
        return
      }
      const intSliderSec = ~~this.$bus.sliderSec
      const intSliderMin = ~~this.$bus.sliderMin
      if (intSliderSec < 0) {
        this.$bus.sliderSec = '0'
        this.$bus.sliderValue = intSliderMin * 60
      } else if (intSliderSec > 59) {
        this.$bus.sliderValue = intSliderMin * 60 + 59
        this.$bus.sliderSec = '59'
      } else {
        this.$bus.sliderValue = intSliderMin * 60 + intSliderSec
      }
      this.onSetMapData()
    },
    onChangeSliderMin() {
      this.$bus.handleGA('timeline_input_min','success')
      if (!/^-{0,}\d{1,}$/.test(this.$bus.sliderMin)) {
        this.$bus.sliderMin = ''
        return
      }
      const maxMin = ~~this.$bus.onConvertSeconds(this.$bus.getSliderMax, 'm')
      const maxSec = ~~this.$bus.onConvertSeconds(this.$bus.getSliderMax, 'ss')
      const intSliderMin = ~~this.$bus.sliderMin
      const intSliderSec = ~~this.$bus.sliderSec

      if (intSliderMin < 0) {
        this.$bus.sliderMin = '0'
        this.$bus.sliderValue = intSliderSec
      } else if (intSliderMin >= maxMin) {
        this.$bus.sliderValue = maxMin * 60 + maxSec
        this.$bus.sliderMin = `${maxMin}`
        this.$bus.sliderSec = `${maxSec}`
      } else {
        this.$bus.sliderValue = intSliderMin * 60 + intSliderSec
      }

      this.onSetMapData()
    },
    onSendActivePointMessage() {
      this.activeWorker.postMessage({
        matchIdList: this.$bus.matchIdList
          .filter((matchId) => matchId.selected)
          .map((x) => x.matchId),
        accountMeta: this.$bus.accountMeta,
        playersPos: this.$bus.playersPos,
        getSliderMax: this.$bus.getSliderMax,
        activeTeamList: this.$bus.activeTeamList,
        matchMeta: this.$bus.matchMeta
      })
    },
    onSetMapData() {
      this.onSetMovementData()
      this.onSetDiepointData()
      this.onSetLandingpointData()
      this.onSetBombData()
      this.onSetSafeZoneData()
      // this.onSetActivepointData()
      this.onSendActivePointMessage()
      this.onSetPlayerReviveData()
      this.onSetBuyShopData()
      this.onSetHotZoneData()
    },
    onGetSelectedPlayer(flat = true) {
      const formatTeamList = this.$bus.activeTeamList
        .map((item) =>
          item.userId.map((ele) => (ele.selected ? ele.userId : ''))
        )
        .map((item) => item.filter((ele) => ele))
      return flat ? formatTeamList.flat() : formatTeamList
    },
    onGetMovementData(selectedPlayerList, matchId) {
      return selectedPlayerList
        .map((playerList) => {
          let playerPosList = this.$bus.accountMeta[matchId]
            .filter((item) => playerList.includes(item.userId))
            .map((account) =>
              this.$bus.playersPos[matchId]
                .filter((data) => data.userId === account.userId)
                .sort((a, b) => a.time - b.time)
                .filter((datax) => datax.time <= this.$bus.sliderValue)
            )
          const length = playerPosList.length
          /**
           * 实时计算符合半径156进行轨迹合并
           */
          if (length >= 1) {
            playerPosList = playerPosList.map((playerPos) => {
              return playerPos.map((item) => {
                const targetPlayerPos = playerPosList
                  .map((ele) => ele.find((x) => x.time === item.time))
                  .filter((x) => x)
                // item.reduce((prev, next) => ~~prev.posX + ~~next.posZ)
                let posXTotal = 0
                let posZTotal = 0
                targetPlayerPos.forEach((ele) => {
                  posXTotal += parseFloat(ele.posX)
                  posZTotal += parseFloat(ele.posZ)
                })
                const meanPosX = posXTotal / targetPlayerPos.length
                const meanPosZ = posZTotal / targetPlayerPos.length
                const distance = Math.sqrt(
                  Math.pow(parseFloat(item.posX) - meanPosX, 2) +
                    Math.pow(parseFloat(item.posZ) - meanPosZ, 2)
                )
                // console.log('轨迹', {
                //   队伍信息: targetPlayerPos,
                //   队伍坐标: {
                //     posX: meanPosX,
                //     posZ: meanPosZ
                //   },
                //   队员信息: {
                //     posX: item.posX,
                //     posZ: item.posZ,
                //     userId: item.userId
                //   },
                //   距离: distance,
                //   时间: item.time,
                //   是否合并: `${distance > 156 ? '否' : '是'}`
                // })
                return {
                  posX: `${ !this.hasMergeMovement ? item.posX : distance > 156 ? item.posX : meanPosX }`,
                  posZ: `${ !this.hasMergeMovement ? item.posZ : distance > 156 ? item.posZ : meanPosZ }`,
                  userId: item.userId,
                  time: item.time,
                  matchId
                }
              })
            })
          }
          return playerPosList
        })
        .flat()
    },
    onSetMovementData() {
      const matchId = this.$bus.getActiveMatchId
      if (this.$bus.getIsMuti) {
        const activeTeam = this.$bus.activeTeamList.find(
          (team) => team.selected || team.userId.some((x) => x.selected)
        )
        if (!activeTeam) {
          this.$bus.movementData = []
          return
        }
        const selectedMatchIdList = this.$bus.matchIdList
          .filter((item) => item.selected)
          .map((x) => x.matchId)
        this.$bus.movementData = selectedMatchIdList
          .map((matchId) => {
            const teamId = activeTeam.team_id
            const selectedPlayerList = this.onGetSelectedPlayer()
            const originPlayerList = this.$bus.matchMeta[
              matchId
            ].team_info.find((team) => team.team_id === teamId).userId
            const targetPlayerList = selectedPlayerList.filter((userId) =>
              originPlayerList.includes(userId)
            )
            return this.onGetMovementData([targetPlayerList], matchId)
          })
          .flat()
          .filter((x) => x && x.length)
        return
      }
      if (!matchId) {
        this.$bus.movementData = []
        return
      }
      this.$bus.movementData = this.onGetMovementData(
        this.onGetSelectedPlayer(false),
        matchId
      )
    },
    onSetActivepointData(activePointData) {
      this.$bus.activePointData = activePointData
    },
    onSetDiepointData() {
      const selectedPlayerList = this.onGetSelectedPlayer()
      // 多场比赛
      if (this.$bus.getIsMuti) {
        const selectedMatchIdList = this.$bus.matchIdList
          .filter((item) => item.selected)
          .map((x) => x.matchId)
        this.$bus.diePointData = selectedMatchIdList
          .map((matchId) => {
            return this.$bus.playersDie[matchId]
              .filter((item) => selectedPlayerList.includes(item.userId))
              .filter((data) => data.time <= this.$bus.sliderValue)
          })
          .flat()
        return
      }
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.diePointData = []
        return
      }
      this.$bus.diePointData = this.$bus.playersDie[matchId]
        .filter((item) => selectedPlayerList.includes(item.userId))
        .filter((data) => data.time <= this.$bus.sliderValue)
    },
    onSetLandingpointData() {
      const selectedPlayerList = this.onGetSelectedPlayer()
      // 多场比赛
      if (this.$bus.getIsMuti) {
        const selectedMatchIdList = this.$bus.matchIdList
          .filter((item) => item.selected)
          .map((x) => x.matchId)
        this.$bus.landingPointData = selectedMatchIdList
          .map((matchId) => {
            return this.$bus.playersLand[matchId]
              .filter((item) => selectedPlayerList.includes(item.userId))
              .filter((data) => data.time <= this.$bus.sliderValue)
          })
          .flat()
        return
      }
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.landingPointData = []
        return
      }
      this.$bus.landingPointData = this.$bus.playersLand[matchId]
        .filter((item) => selectedPlayerList.includes(item.userId))
        .filter((data) => data.time <= this.$bus.sliderValue)
    },
    onSetBombData() {
      if (this.$bus.getIsMuti) {
        return
      }
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.bombData = []
        return
      }
      this.$bus.bombData = this.$bus.bombZone[matchId].filter(
        (data) =>
          data.start_time <= this.$bus.sliderValue &&
          this.$bus.sliderValue <= data.end_time
      )
    },
    onSetSafeZoneData() {
      if (this.$bus.getIsMuti) {
        return
      }
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.safeZoneData = []
        return
      }
      const target = this.$bus.safeZone[matchId].find(
        (data) =>
          data.start_time <= this.$bus.sliderValue * 1000 &&
          this.$bus.sliderValue * 1000 <= data.end_time
      )
      this.$bus.safeZoneData = target ? target : {}
    },
    onSetPlayerReviveData() {
      const selectedPlayerList = this.onGetSelectedPlayer()
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.playerReviveData = []
        return
      }
      this.$bus.playerReviveData = this.$bus.playerRevive[matchId]
        .filter((item) => selectedPlayerList.includes(item.userId))
        .filter((data) => data.time <= this.$bus.sliderValue)
    },
    onSetBuyShopData() {
      const selectedPlayerList = this.onGetSelectedPlayer()
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.buyShopData = []
        return
      }
      this.$bus.buyShopData = this.$bus.buyShop[matchId]
        .filter((item) => selectedPlayerList.includes(item.userId))
        .filter((data) => data.time <= this.$bus.sliderValue)
    },
    onSetHotZoneData() {
      const matchId = this.$bus.getActiveMatchId
      if (!matchId) {
        this.$bus.hotzoneData = []
        return
      }
      this.$bus.hotzoneData = this.$bus.hotZone[matchId]
        .filter((data) => data.time <= this.$bus.sliderValue)
    },
    changeMergeMovement(val) {
      this.$bus.handleGA('MergeMovement', val ? 'open' : 'close')
    },
    changeSpeed(val) {
      this.$bus.handleGA('selectSpeed', `${val}`)
    }
  },
  watch: {
    '$bus.mapControlsFlag.movementFlag': {
      handler(val) {
        if (!val) {
          this.hasMergeMovement = false
        } else {
          this.hasMergeMovement = true
        }
      },
      deep: true
    },
    // 增加监听，在多场时取消默认选中poison
    '$bus.getIsMuti': {
      handler(val) {
        if(val) {
          this.$bus.mapControlsFlag.poisonFlag = false
        }
      },
      deep: true
    },
    '$bus.activeTeamList': {
      handler() {
        this.onSetMapData()
      },
      deep: true
    },
    '$bus.sliderValue': {
      handler(val) {
        if (val === this.$bus.getSliderMax && this.isPlay) {
          clearInterval(this.playTimer)
          this.$bus.sliderValue = 0
          this.$bus.sliderSec = `${this.$bus.onConvertSeconds(
            this.$bus.sliderValue,
            's.S'
          )}`
          this.$bus.sliderMin = `${this.$bus.onConvertSeconds(
            this.$bus.sliderValue,
            'm'
          )}`
          this.isPlay = false
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.home-map {
  position: relative;
  width: px2vw(1220);
  height: 100%;
  background-color: $el-home;
  border-radius: 10px;

  @include float {
    width: 100%;
  }

  &.home-map__fullscreen {
    width: 100%;
  }

  & /deep/ .el-slider__runway {
    background-color: $el-disabled;
  }

  & /deep/ .el-slider__bar {
    background-color: $el-focus;
  }

  & /deep/ .el-slider__button {
    background-color: $bg-map-slider;
  }

  .home-map-wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    .map-show {
      position: relative;
      width: px2vw(950);
      height: 100%;
      padding-top: px2vh(40);

      @include float {
        // 950 / 1220
        width: 78%;
      }

      &.map-show__fullscreen {
        width: 100%;
        padding-top: 0;
      }

      .map-show-container {
        position: relative;
        width: px2vw(870);
        height: px2vh(870);
        margin: 0 auto;
        z-index: 5;
        overflow: hidden;

        @include float {
          // 870 / 950
          width: 92%;
        }

        &.map-show-container__fullscreen {
          width: 100%;
          height: 100%;
        }
      }

      .map-show-control {
        position: absolute;
        bottom: px2vh(10);
        left: 50%;
        transform: translateX(-50%);
        width: px2vw(830);
        height: auto;
        z-index: 10;

        @include float {
          // 700 / 950
          width: 74%;
        }

        .show-control-wrapper {
          width: 100%;
          height: px2vh(100);
          border-radius: 5px;
          background-color: $bg-map-control;
        }

        .show-control-slider {
          position: relative;
          width: px2vw(600);
          height: px2vh(48);
          margin: 0 auto;
          left: -30px;

          @include float {
            // 700 / 950
            width: 86%;
          }
        }

        .show-control-slider-mark {
          position: absolute;
          top: 16px;
          height: 6px;
          border-radius: 3px;
          // cursor: pointer;
          pointer-events: none;

          &.show-control-slider-mark__bomb {
            background-color: $bg-map-mark;
          }

          &.show-control-slider-mark__safezone {
            width: 6px;
            background-color: $el-text;
          }
        }

        .show-control-group {
          position: relative;
          width: px2vw(830 - 148);
          margin: 0 auto;

          @include float {
            // 700 / 950
            width: 86%;
          }

          .playControl {
            position: absolute;
            right: 0;
            top: 0;
            @include flexrc;

            .selectPlaySpeed {
              margin-right: 10px;
              margin-top: px2vh(-3.5);
              &/deep/ .el-select{
                width: px2vh(74);
                height: px2vh(32);

                & .el-input__inner{
                  background: #1A1D24;
                  border: 1px solid #334157;
                  border-radius: 5px;
                  color: rgba(255, 255, 255, 0.5);
                  height: px2vh(32);
                  line-height: px2vh(32);
                  padding: 0 8px;

                  &:focus-visible{
                    border: 1px solid #50ABFF;;
                  }
                }

                & .el-select__caret{
                  color: #CCCCCC;
                  transform: rotate(0deg);
                  &::before{
                    content: "";
                    background-image: url("../../../assets/images/arrow_down.png");
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 6px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
                & .el-select__caret.is-reverse{
                  transform: rotate(180deg);
                }
              }
            }

            .selectPlayButton {

              .controlButton {
                display: inline-block;

                &.playIcon{
                  background-image: url("../../../assets/images/icon_play_common.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat no-repeat;
                  width: px2vh(32);
                  height: px2vh(32);

                  &:hover{
                    background-image: url("../../../assets/images/icon_play_hover.png");
                  }
                  &:active{
                    background-image: url("../../../assets/images/icon_play_select.png");
                  }
                }

                &.stopIcon{
                  background-image: url("../../../assets/images/icon_stop_common.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat no-repeat;
                  width: px2vh(32);
                  height: px2vh(32);

                  &:hover{
                    background-image: url("../../../assets/images/icon_stop_hover.png");
                  }
                  &:active{
                    background-image: url("../../../assets/images/icon_stop_select.png");
                  }
                }
              }
            }
            & .isSpeedSelect{
              background-color: #1A1D24;
              border: 1px solid #334157;
              border-radius: 5px;
              color: #334157;
              width: px2vh(74);
              height: px2vh(32);
              font-size: 14px;
              font-family: 'Roboto';


              &:focus-visible {
                outline: none;
                border: 1px solid #50ABFF;
              }
            }
          }
        }

        .show-control-form {
          display: flex;
          justify-content: space-between;
          width: px2vw(302);
          margin: 0 auto;
          position: relative;
          left: -20px;
        }
        .show-control__button--reduce,
        .show-control__button--increase {
          width: px2vw(56);
          height: px2vh(32);
          font-size: 16px;
          color: $el-text--gray;

          @include lg {
            font-size: 14px;
          }

          &:hover {
            color: $el-focus;
          }

          &:active {
            background-color: $el-focus;
            color: $el-text;
          }

          &:disabled {
            color: $el-disabled;
          }
        }

        .show-control__input--min,
        .show-control__input--sec {
          position: relative;
          width: px2vw(80);
          height: px2vh(32);

          & /deep/ .widget-input__inner {
            padding: 0 px2vw(10) 0 px2vw(10);
          }

          .show-control__input--unit {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: px2vw(10);
            font-size: 16px;
            color: $el-disabled;

            @include lg {
              font-size: 14px;
            }
          }
        }

        .show-control-survival {
          position: absolute;
          top: px2vh(12);
          right: -70px;
          color: $el-em;
          font-size: 16px;

          @include lg {
            font-size: 10px;
          }
        }

        .show-control-mark {
          @include flexjc;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: px2vw(150);
        }

        .show-control-hint {
          position: relative;
          width: 100%;
          height: auto;
          margin-top: px2vh(6);
          border-radius: 5px;
          background-color: $bg-map-control;
        }
      }
    }
  }

  .map-control {
    position: relative;
    /* width: px2vw(270); */
    flex: 1;
    height: 100%;
    padding-top: px2vh(40);

    &.map-control__fullscreen {
      position: fixed;
      top: 50px;
      right: 0;
      width: 238px;
      flex: none;
      padding-top: 0;
      z-index: 10;
    }

    .map-control-icons {
      display: flex;
      width: 100%;
      height: px2vh(40);

      .control-icon__more {
        margin: 0 px2vw(10) 0 px2vw(28);
      }

      .control-icon__zoom-out {
        border-radius: 5px 0 0 5px;
      }

      .control-icon__zoom-in {
        border-radius: 0 5px 5px 0;
      }

      .control-icon__max {
        margin-left: px2vw(10);
      }
    }

    .map-control-list {
      position: relative;
      width: 100%;
      padding: px2vh(25) 0 0;

      &.map-control-list__fullscreen {
        background-color: $bg-map-control;
        padding: 0 0 0 px2vh(20);
        margin-top: px2vh(20);
        border-radius: 5px;
      }

      .map-control-triangle {
        position: absolute;
        top: px2vh(-24);
        left: px2vw(38);
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-bottom: 12px solid $bg-map-control;
      }

      .widget-control {
        @include flexac;
        width: 100%;
        height: px2vh(50);

        .checkboxPart{
          @include flexac;
          width: 100%;
        }

        /deep/ .widget-checkbox__label {
          color: $el-text--gray;
          font-size: 16px;

          @include lg {
            font-size: 16px;
          }

          // @include md {
          //   font-size: 11px;
          // }

          // @include sm {
          //   font-size: 11px;
          // }
        }
      }

      .widget-control:nth-of-type(1){
        margin-bottom: 20px;
      }

      .flexColumn{
        flex-direction: column;

        & .switchPart{
          margin-top: px2vw(8);
          align-self: start;
          margin-left: 25px;

          .elSwitch{
            width: 28px;
            height: 16px;
            margin-right: 8px;
            border-radius: 16px;
            position: relative;
            top: -2px;

            &/deep/ > span {
              width: 28px;
              height: 16px;

              &::after{
                width: 12.5px;
                height: 12.5px;
              }
            }

            &.is-checked/deep/ > span::after{
              margin-left: -13px;
            }
          }

          & > span {
            color: #CCCCCC;
            font-size: 14px;
          }
        }
      }

      .widget-control-mark {
        /* position: relative; */
        /* top: px2vh(2); */
        /* width: px2vw(20); */
        /* height: px2vw(20); */
        width: 20px;
        height: 20px;
        margin: 0 0 0 px2vw(8);

        &.widget-control-mark__deadpoint {
          background: url('../../../assets/images/icon-deadpoint-control.png') top
            center / 100% 100% no-repeat;
        }

        &.widget-control-mark__landingpoint {
          background: url('../../../assets/images/icon-landingpoint-control.png') top
            center / 100% 100% no-repeat;
        }

        &.widget-control-mark__bomb {
          background: url('../../../assets/images/icon-bomb.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__safezone {
          background: url('../../../assets/images/icon-safezone.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__poison {
          background: url('../../../assets/images/icon-poison.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__activepoint {
          background: url('../../../assets/images/icon-activepoint.png') top
            center / 100% 100% no-repeat;
        }

        &.widget-control-mark__movement {
          width: 20px;
          height: 20px;
          background: url('../../../assets/images/icon-movement-control.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__supplies {
          background: url('../../../assets/images/icon-supplies.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__airline {
          background: url('../../../assets/images/icon_air.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__revive {
          background: url('../../../assets/images/icon-revive.png') top center /
            100% 100% no-repeat;
        }

        &.widget-control-mark__slotMachine {
          background: url('../../../assets/images/icon-slotMachine.png') top center /
            100% 100% no-repeat;
        }
      }
    }
  }
}
</style>
