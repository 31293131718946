<template>
  <div
    class="widget-matchid"
    :class="{ 'widget-matchid__unselected': !selected }"
    @click="onWidgetClick"
    :style="getStyle"
  >
    <span class="widget-matchid__inner">{{ matchId }}</span>
    <span class="widget-matchid__close" @click.stop="onIconClick"></span>
  </div>
</template>

<script>
import { getMatchColor } from '@/utils/utils'

export default {
  props: {
    matchId: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getStyle() {
      const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
      const index = matchIdList.findIndex((matchId) => matchId === this.matchId)
      const matchColor = getMatchColor(index)
      return {
        background: matchColor.bgColor,
        border: `1px solid ${matchColor.color}`
      }
    }
  },
  methods: {
    onWidgetClick() {
      this.$emit('click-widget-matchid')
    },
    onIconClick() {
      this.$emit('click-icon-close')
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-matchid {
  @include flexac;
  position: relative;
  width: px2vw(265);
  height: px2vh(32);
  padding: 0 px2vw(40) 0 px2vw(10);
  margin-top: px2vw(10);
  border-radius: 5px;
  color: $el-text;
  cursor: pointer;
  font-size: 14px;
  transition: all ease-out 0.3s;

  @include lg {
    font-size: 12px;
  }

  // @include md {
  //   font-size: 10px;
  // }

  // @include sm {
  //   font-size: 10px;
  // }

  &.widget-matchid__unselected {
    color: $el-disabled;
    border: 1px solid $el-form !important;
    background: $el-home !important;
  }

  .widget-matchid__close {
    position: absolute;
    right: px2vw(13);
    top: 50%;
    transform: translateY(-50%);
    /* width: px2vw(10);
    height: px2vw(10); */
    width: 8px;
    height: 8px;
    background: url('../../assets/images/icon-close.png') top center / 100% 100%
      no-repeat;
    @include extend-click;
  }
}
</style>
