<template>
  <widget-mask>
    <div class="widget-loading">
      <div class="widget-loading-title">Loading</div>
      <div class="widget-loading-wrapper">
        <span v-for="i in 3" :key="i"></span>
      </div>
    </div>
  </widget-mask>
</template>

<script>
import WidgetMask from '../widget-mask'

export default {
  components: {
    WidgetMask
  }
}
</script>

<style lang="scss" scoped>
.widget-loading {
  @include flexrc;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);

  .widget-loading-title {
    width: 50%;
    padding-bottom: px2vh(10);
    text-align: center;
    font-size: 16px;
    color: $el-text;

    @include lg {
      font-size: 14px;
    }

    // @include md {
    //   font-size: 12px;
    // }

    // @include sm {
    //   font-size: 12px;
    // }
  }

  .widget-loading-wrapper {
    width: 50%;

    > span {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      animation: 3s infinite linear;

      &:nth-child(1) {
        background-color: $el-loading--red;
        animation: kiri 1.2s infinite linear;
      }

      &:nth-child(2) {
        background-color: $el-loading--blue;
      }

      &:nth-child(3) {
        background-color: $el-loading--cyan;
        animation: kanan 1.2s infinite linear;
      }
    }
  }

  @keyframes kanan {
    0% {
      -webkit-transform: translateX(20px);
    }

    50% {
      -webkit-transform: translateX(-20px);
    }

    100% {
      -webkit-transform: translateX(20px);
      z-index: 200;
    }
  }

  @keyframes kiri {
    0% {
      -webkit-transform: translateX(-20px);
      z-index: 200;
    }
    50% {
      -webkit-transform: translateX(20px);
    }
    100% {
      -webkit-transform: translateX(-20px);
    }
  }
}
</style>
