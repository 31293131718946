import Vue from 'vue'
import moment from 'moment'
import {addGA} from '@/utils/assist'
import {getMapScale} from '@/utils/utils'
export default new Vue({
  data() {
    return {
      toast: {
        show: false,
        content: ''
      },
      loading: false,
      // 业务
      matchId: '',
      matchIdList: [],
      teamList: {},
      activeTeamList: [],
      accountMeta: {},
      playersPos: {},
      matchMeta: {},
      playersDie: {},
      playersLand: {},
      safeZone: {},
      bombZone: {},
      playerRevive: {},
      buyShop: {},
      hotZone: {},
      skydiving: [],
      fullscreenFlag: false,
      mapZoom: 0,
      mapId: 0,
      mapPK: 0,
      // 控件
      allTeamFlag: false,
      mapControlsFlag: {
        movementFlag: false,
        activepointFlag: false,
        deadpointFlag: false,
        landingpointFlag: false,
        bombFlag: false,
        safezoneFlag: false,
        poisonFlag: false,
        suppliesFlag: false,
        airlineFlag: false,
        reviveFlag: false,
        slotMachineFlag: false
      },
      sliderValue: 0,
      sliderMin: '',
      sliderSec: '',
      // 地图
      movementData: [],
      diePointData: [],
      landingPointData: [],
      activePointData: [],
      bombData: [],
      safeZoneData: [],
      poisonData: [],
      airlineData: [],
      playerReviveData: [],
      buyShopData: [],
      hotZoneData: [],
      activeIndex: -1,
      activeLayer: -1,
      // 地图配置
      maxBounds: {
        1: [
          [
            [1010 * getMapScale(1), -380 * getMapScale(1)],
            [-790 * getMapScale(1), 1420 * getMapScale(1)]
          ]
        ],
        3: [
          [
            [733 * getMapScale(3), -987 * getMapScale(3)],
            [-1067 * getMapScale(3), 813 * getMapScale(3)]
          ]
        ],
        4: [
          [
            [700 * getMapScale(4), -700 * getMapScale(4)],
            [-700 * getMapScale(4), 700 * getMapScale(4)]
          ]
        ],
        5: [
          [
            [900 *getMapScale(5), -900 * getMapScale(5)],
            [-900 * getMapScale(5), 900 * getMapScale(5)]
          ]
        ],
        14: [
          [
            [850 *getMapScale(14), -850 * getMapScale(14)],
            [-850 * getMapScale(14), 850 * getMapScale(14)]
          ]
        ]
      },
      mapConfig: {
        1: {
          url: require('../assets/images/map-1.jpg'),
          bounds: [
            [
              [-790 * getMapScale(1), -380 * getMapScale(1)],
              [1010 * getMapScale(1), 1420 * getMapScale(1)]
            ]
          ],
          centerX: 520 * getMapScale(1),
          centerY: 110 * getMapScale(1)
        },
        3: {
          url: require('../assets/images/map-3.jpg'),
          bounds: [
            [
              [-1067 * getMapScale(3), -987 * getMapScale(3)],
              [733 * getMapScale(3), 813 * getMapScale(3)]
            ]
          ],
          centerX: -167 * getMapScale(3),
          centerY: -87 * getMapScale(3)
        },
        4: {
          url: require('../assets/images/map-4.jpg'),
          bounds: [
            [
              [-700 * getMapScale(4), -700 * getMapScale(4)],
              [700 * getMapScale(4), 700 * getMapScale(4)]
            ]
          ],
          centerX: 0,
          centerY: 0
        },
        5: {
          url: require('../assets/images/map-5.jpg'),
          bounds: [
            [
              [-900 * getMapScale(5), -900 * getMapScale(5)],
              [900 * getMapScale(5), 900 * getMapScale(5)]
            ]
          ],
          centerX: 0,
          centerY: 0
        },
        14: {
          url: require('../assets/images/map-14.png'),
          bounds: [
            [
              [-850 * getMapScale(14), -850 * getMapScale(14)],
              [850 * getMapScale(14), 850 * getMapScale(14)]
            ]
          ],
          centerX: 0,
          centerY: 0
        }
      },
      mapVertexConfig: {},
      // 判断之前是否有新的点和包：航线，资源区，售卖机，复活点
      airlineDataFlag: false,
      suppliesDataFlag: false,
      slotMachineDataFlag: false,
      reviveDataFlag: false
    }
  },
  computed: {
    getSliderMax() {
      const activeMatchId = this.getActiveMatchId
      if (!activeMatchId) {
        return 0
      }
      if (this.getIsMuti) {
        const matchFinishedTimeList = this.matchIdList
          .filter(matchId => matchId.selected)
          .map(x => x.matchId)
          .map(
            matchId =>
              this.matchMeta[matchId].match_finished.minutes * 60 +
              this.matchMeta[matchId].match_finished.second +
              30
          )
        return Math.max.apply(null, matchFinishedTimeList)
      }
      return (
        this.matchMeta[activeMatchId].match_finished.minutes * 60 +
        this.matchMeta[activeMatchId].match_finished.second +
        30
      )
    },
    getActiveMatchId() {
      if (!this.matchIdList.length) {
        return ''
      }
      const activeMatchId = this.matchIdList.find(item => item.selected)
      return activeMatchId ? activeMatchId.matchId : ''
    },
    getIsMuti() {
      return (
        this.matchIdList.slice().reduce((prev, next) => {
          if (next.selected) {
            prev += 1
          }
          return prev
        }, 0) > 1
      )
    }
  },
  methods: {
    handleGA(key,value){
      addGA(key,value)
    },
    setToast(toast) {
      this.toast = toast
    },
    setLoading(loading) {
      this.loading = loading
    },
    onGetTeamKills(teamId) {
      // 单场或多场
      if (this.getIsMuti) {
        const matchIdList = this.matchIdList
          .filter(matchId => matchId.selected)
          .map(x => x.matchId)
        let totalKills = 0
        matchIdList.forEach(matchId => {
          totalKills += this.accountMeta[matchId]
            .filter(data => data.team_id === teamId)
            .reduce((sum, li) => sum + li.match_dump.kills, 0)
        })
        return totalKills
      }
      return this.accountMeta[this.getActiveMatchId]
        .filter(data => data.team_id === teamId)
        .reduce((sum, li) => sum + li.match_dump.kills, 0)
    },
    onGetPlayerInfo(userId, teamId) {
      // 单场或多场
      if (this.getIsMuti) {
        const matchIdList = this.matchIdList
          .filter(matchId => matchId.selected)
          .map(x => x.matchId)
        return matchIdList
          .map(matchId => this.accountMeta[matchId])
          .flat()
          .find(data => data.userId === userId && data.team_id === teamId)
      }
      return this.accountMeta[this.getActiveMatchId].find(
        data => data.userId === userId && data.team_id === teamId
      )
    },
    onGetPlayerKills(userId, teamId) {
      if (this.getIsMuti) {
        const matchIdList = this.matchIdList
          .filter(matchId => matchId.selected)
          .map(x => x.matchId)
        return matchIdList
          .map(matchId => this.accountMeta[matchId])
          .flat()
          .filter(data => data.userId === userId && data.team_id === teamId)
          .reduce((sum, li) => sum + li.match_dump.kills, 0)
      }
      return this.onGetPlayerInfo(userId, teamId).match_dump.kills
    },
    onConvertSeconds(seconds, format) {
      return moment
        .utc(moment.duration(seconds, 'seconds').asMilliseconds())
        .format(format)
    },
    onGetPlayerSurvival(userId, teamId) {
      if (this.getIsMuti) {
        const matchIdList = this.matchIdList
          .filter(matchId => matchId.selected)
          .map(x => x.matchId)
        const target = matchIdList
          .map(matchId => this.accountMeta[matchId])
          .flat()
          .filter(data => data.userId === userId && data.team_id === teamId)

        return Math.round(
          target.reduce((sum, li) => sum + li.match_dump.survival_time, 0) /
            target.length
        )
      }
      return this.onGetPlayerInfo(userId, teamId).match_dump.survival_time
    }
  }
})
