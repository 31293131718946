<template>
  <div class="home" :class="$bus.fullscreenFlag ? 'home__fullscreen' : ''">
    <home-map />
    <home-team v-show="!$bus.fullscreenFlag" />
  </div>
</template>

<script>
import HomeMap from './components/home-map.vue'
import HomeTeam from './components/home-team.vue'
export default {
  components: {
    HomeMap,
    HomeTeam
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: px2vw(1840);
  height: px2vh(1000);

  @include float {
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &.home__fullscreen {
    width: 100%;
    height: 100%;
  }
}
</style>
