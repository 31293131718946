var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-team"},[_c('div',{staticClass:"team-info",class:_vm.team.selected || _vm.team.userId.some(function (player) { return player.selected; })
        ? 'team-info--selected'
        : ''},[_c('widget-checkbox',{attrs:{"indeterminate":!_vm.team.selected && _vm.team.userId.some(function (player) { return player.selected; }),"value":_vm.team.selected},on:{"change":_vm.onTeamClick}}),_c('div',{staticClass:"team-info-name"},[_vm._v("#"+_vm._s(_vm.team.team_id +" "+ _vm.team.team_name))]),_c('div',{staticClass:"team-info-color",class:_vm.onGetTeamColor(_vm.team.team_id - 1) === '#000'
          ? 'team-info-color--border'
          : '',style:({ backgroundColor: _vm.onGetTeamColor(_vm.team.team_id - 1) })}),_c('div',{staticClass:"team-info-kills",on:{"click":_vm.isCollapse}},[_vm._v(" "+_vm._s(_vm.$bus.onGetTeamKills(_vm.team.team_id))+" Kills ")]),_c('i',{staticClass:"icon-down",class:{up:_vm.isUp},on:{"click":_vm.isCollapse}})],1),(_vm.team.userId && _vm.team.userId.length)?_c('div',{ref:"isTeamsInfo",staticClass:"team-members transitionCollapse"},_vm._l((_vm.team.userId),function(player,index){return _c('div',{key:index,ref:"teamMemberRef",refInFor:true,staticClass:"team-member",class:player.selected ? 'team-member--selected' : ''},[_c('transition',{attrs:{"name":"slider"}},[(
            _vm.$bus.getIsMuti &&
            _vm.$bus.activeIndex === index &&
            _vm.$bus.activeLayer === _vm.layer
          )?_c('widget-panel',{attrs:{"userId":player.userId,"teamId":_vm.team.team_id,"layer":_vm.layer}}):_vm._e()],1),_c('widget-checkbox',{attrs:{"value":player.selected},on:{"change":function($event){return _vm.onMemberClick(index)}}}),_c('div',{staticClass:"team-member-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.onShowPanel(index, player.userId)}}},[_c('div',{staticClass:"team-member-name"},[_vm._v(" "+_vm._s(_vm.$bus.onGetPlayerInfo(player.userId, _vm.team.team_id).nickname)+" ")]),_c('div',{staticClass:"team-member-info"},[_vm._v(" "+_vm._s(_vm.$bus.onConvertSeconds( _vm.$bus.onGetPlayerSurvival(player.userId, _vm.team.team_id), 'm[m] ss[s]' ))+" | "+_vm._s(_vm.$bus.onGetPlayerKills(player.userId, _vm.team.team_id))+" kills ")])])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }