// -------------GA start--------
/**
 *
 * @param {*} region
 * @param {*} uid
 */
 export const initGA = (dataLogName = '') => {
    if (!dataLogName) {
        dataLogName = window.gaEventLogName
    }
    window.gtag('config', window.analyticsId, {
        // dimension1: region,
        page_title: dataLogName, // defined in webpack
        page_path: '/index.html'
    })
    // window.gtag('set', {user_id: uid})
    //console.log(`${PROJECT_NAME} region ${region}, uid ${uid}`)
}
/**
 *
 * @param {*} event
 * @param {*} status
 */
export const addGA = (event, status) => {
    console.log(`event: ${event}, status: ${status}`)
    const report = {
        event_label: status,
        ts: parseInt(Date.parse(new Date()) / 1000)
    }
    window.gtag('event', event, report)
}
/**
 *
 * @param {*} err
 */
export const errorGA = err => {
    window.gtag('event', 'exception', {
        description: err,
        fatal: false
    })
}
// -------------GA end--------