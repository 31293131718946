<template>
  <div
    class="widget-icon"
    :class="[`widget-icon__${type}`, disabled ? 'widget-icon__disabled' : '']"
    @click="onClick"
  >
    <span
      class="iconfont"
      :class="`icon-${type}`"
      v-if="type !== 'reset'"
    ></span>
    <span class="icon-text" v-else>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click-icon')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-icon {
  @include flexrc;
  /* width: px2vw(40); */
  /* height: px2vw(40); */
  width: 38px;
  height: 38px;
  background-color: $el-icon;
  cursor: pointer;
  border-radius: 5px;
  transition: all ease-out 0.3s;

  .iconfont {
    font-size: 20px;
    transition: all ease-out 0.3s;

    @include lg {
      font-size: 18px;
    }
  }

  .icon-text {
    font-size: 12px;
    transition: all ease-out 0.3s;
    text-align: center;

    @include lg {
      font-size: 10px;
    }

    // @include md {
    //   font-size: 8px;
    // }
  }

  &:hover > .iconfont {
    color: $el-focus;
  }

  &:active {
    background-color: $el-focus;

    .iconfont {
      color: $el-text;
    }
  }

  &.widget-icon__disabled {
    /* pointer-events: none; */
    cursor: not-allowed;

    .iconfont {
      color: $el-disabled;
    }
  }
}
</style>
