<template>
  <l-map
    class="widget-map"
    :crs="crs"
    :options="mapOptions"
    :maxBounds="maxBounds"
    :center="[mapConfig.centerX, mapConfig.centerY]"
    ref="mapRef"
    @ready="onMapReady"
  >
    <!-- 自定义地图 -->
    <l-image-overlay
      :url="mapConfig.url"
      :bounds="mapConfig.bounds"
      ref="imageRef"
    >
    </l-image-overlay>
    <!-- 轨迹 -->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.movementFlag &&
        $bus.movementData.length
      "
    >
      <l-polyline
        v-for="(player, index) in $bus.movementData"
        ref="polylineRef"
        :key="`line${index}`"
        :color="getTeamPolylineColor(player)"
        :lat-lngs="getMovementLatLngs(player)"
      >
      </l-polyline>
    </template>
    <!-- 活跃点 -->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.activepointFlag &&
        $bus.activePointData.length
      "
    >
      <l-circle-marker
        v-for="(player, index) in $bus.activePointData"
        :key="`active${index}`"
        :lat-lng="getLatLngs(player)"
        :radius="getActivepointRadius"
        :stroke="false"
        :fill="true"
        :fillColor="getTeamActivePointColor(player)"
        :fillOpacity="1"
        @click="$bus.handleGA('active_point','success')"
      >
        <l-popup class="popup-point" :options="{ closeButton: false }">
          <div
            class="popup-point-matchid"
            v-if="$bus.getIsMuti"
            :style="getMatchIdStyle(player.matchId)"
          >
            {{ player.matchId }}
          </div>
          <div class="popup-point-title">
            <p>#{{$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+ $bus.onGetPlayerInfo(player.userId, player.teamId).team_name }}</p>
<!--            <p>{{ getTime(player.time) }}</p>-->
          </div>
          <div class="popup-point-subtitle">
            Active Point ({{ player.members.length }})
          </div>
          <div
            class="popup-point-text"
            v-for="member in player.members"
            :key="member.userId"
          >
            {{ $bus.onGetPlayerInfo(member.userId, player.teamId) === undefined ? "" : $bus.onGetPlayerInfo(member.userId, player.teamId).nickname  }}
          </div>
        </l-popup>
      </l-circle-marker>
    </template>
    <!-- 死亡点 -->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.deadpointFlag &&
        $bus.diePointData.length
      "
    >
      <l-marker
        v-for="(player, index) in $bus.diePointData"
        :key="`deadpoint${index}`"
        :lat-lng="getLatLngs(player)"
        :icon="getPointIconUrl('deadpoint', player)"
        @click="$bus.handleGA('dead_point','success')"
      >
        <l-popup class="popup-point" :options="{ closeButton: false }">
          <div
            class="popup-point-matchid"
            v-if="$bus.getIsMuti"
            :style="getMatchIdStyle(player.matchId)"
          >
            {{ player.matchId }}
          </div>
          <div class="popup-point-title">
            <p>#{{$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+  $bus.onGetPlayerInfo(player.userId, player.teamId).team_name }}</p>
            <p>{{ getTime(player.time) }}</p>
          </div>
          <div class="popup-point-subtitle">
            <!--优化点2-->
<!--            <span class="popup-point-icon popup-point-icon__deadpoint"></span>-->
            <span>DEAD (1)</span>
          </div>
          <div class="popup-point-text">
            {{ $bus.onGetPlayerInfo(player.userId, player.teamId) === undefined ? "" : $bus.onGetPlayerInfo(player.userId, player.teamId).nickname }}
          </div>
        </l-popup>
      </l-marker>
    </template>
    <!-- 落地点 -->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.landingpointFlag &&
        $bus.landingPointData.length
      "
    >
      <l-marker
        v-for="(player, index) in $bus.landingPointData"
        :key="`landingpoint${index}`"
        :lat-lng="getLatLngs(player)"
        :icon="getPointIconUrl('landingpoint', player)"
        @click="$bus.handleGA('landing_point','success')"
      >
        <l-popup class="popup-point" :options="{ closeButton: false }">
          <div
            class="popup-point-matchid"
            v-if="$bus.getIsMuti"
            :style="getMatchIdStyle(player.matchId)"
          >
            {{ player.matchId }}
          </div>
          <div class="popup-point-title">
            <p>#{{$bus.onGetPlayerInfo(player.userId, player.teamId).team_id +" "+  $bus.onGetPlayerInfo(player.userId, player.teamId).team_name }}</p>
            <p>{{ getTime(player.time) }}</p>
          </div>
          <div class="popup-point-subtitle">
            <!--优化点2-->
<!--            <span-->
<!--              class="popup-point-icon popup-point-icon__landingpoint"-->
<!--            ></span>-->
            <span>LAND (1)</span>
          </div>
          <div class="popup-point-text">
            {{ $bus.onGetPlayerInfo(player.userId, player.teamId) === undefined ? "" : $bus.onGetPlayerInfo(player.userId, player.teamId).nickname  }}
          </div>
        </l-popup>
      </l-marker>
    </template>
    <!-- 爆炸点 -->
    <template v-if="$bus.mapControlsFlag.bombFlag && $bus.bombData.length">
      <l-circle
        v-for="(player, index) in $bus.bombData"
        :key="`bomb${index}`"
        :lat-lng="getLatLngs(player)"
        :radius="getBombRadius(player)"
        :stroke="false"
        fillColor="#FD7E14"
        :fillOpacity="0.6"
        @click="$bus.handleGA('bomb_point','success')"
      />
    </template>
    <!-- 安全区 -->
    <template
      v-if="
        $bus.mapControlsFlag.safezoneFlag &&
        Object.keys($bus.safeZoneData).length
      "
    >
      <l-circle
        :lat-lng="getSafeZoneLatLngs"
        :radius="getSafeZoneRadius"
        dashArray="5"
        color="#fff"
        :fill="false"
      />
    </template>
    <!-- 毒圈 -->
    <template
      v-if="
        $bus.mapControlsFlag.poisonFlag && Object.keys($bus.safeZoneData).length
      "
    >
      <l-polygon
        :lat-lngs="getPoisonLatLngs"
        :stroke="false"
        fillColor="#5F22C1"
        :fillOpacity="0.5"
      ></l-polygon>
    </template>

    <!-- 航线 -->
<!--    <template-->
<!--      v-if="-->
<!--        $bus.getActiveMatchId && Object.keys($bus.airlineData).length-->
<!--      "-->
<!--    >-->
<!--      <l-polyline-->
<!--        ref="airlineRef"-->
<!--        :key="`airline`"-->
<!--        :fill="true"-->
<!--        :lStyle="setStyle"-->
<!--        :dashArray="dashArray"-->
<!--        :lat-lngs="getAirlineLatLngs"-->
<!--        :options="lineOption"-->
<!--      >-->
<!--      </l-polyline>-->
<!--    </template>-->

    <!-- 复活点 不需要点击和popup-->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.reviveFlag &&
        $bus.playerReviveData.length
      "
    >
      <l-marker
        v-for="(player, index) in $bus.playerReviveData"
        :key="`playerRevive${index}`"
        :lat-lng="getPlayerReviveLatLngs(player)"
        :icon="getPointIconUrl('revive')"
      >
      </l-marker>
    </template>
    <!-- 贩卖机 不需要点击和popup-->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.slotMachineFlag &&
        $bus.buyShopData.length
      "
    >
      <l-marker
        v-for="(player, index) in $bus.buyShopData"
        :key="`buyShop${index}`"
        :lat-lng="getBuyShopLatLngs(player)"
        :icon="getPointIconUrl('slotMachine')"
      >
      </l-marker>
    </template>
    <!-- 高级资源区 不需要点击和popup-->
    <template
      v-if="
        $bus.getActiveMatchId &&
        $bus.mapControlsFlag.suppliesFlag &&
        $bus.hotzoneData.length
      "
    >
      <l-marker
        v-for="(player, index) in $bus.hotzoneData"
        :key="`hotzone${index}`"
        :lat-lng="getHotzoneLatLngs(player)"
        :icon="getPointIconUrl('supplies')"
      >
      </l-marker>
    </template>
    <!-- 地图名字 -->
    <template>
      <l-marker
        :key="`mapName`"
        :icon="getMapName"
        :lat-lng="mapNameLatLng">
      </l-marker>
    </template>
  </l-map>
</template>

<script>
import {
  LMap,
  LImageOverlay,
  LPolyline,
  LMarker,
  LPopup,
  LIcon,
  LCircle,
  LPolygon,
  LCircleMarker,
  LControl
} from 'vue2-leaflet'
import { icon } from 'leaflet'
import {
  getTeamColor,
  getMapScale,
  getMapOptions,
  lerp,
  getMatchColor,
  getIconIndex
} from '@/utils/utils'
import L from 'leaflet'
import 'leaflet-polylinedecorator'

export default {
  data() {
    return {
      setStyle: {
        border: '1px dashed #000000'
      },
      dashArray: '5, 5',
      moveTime: 0,
      arrow: undefined,
      arrowHead: undefined,
      movementArrowHead: undefined,
      movementArrowHeadList: [],
      movementDataList: [],
      movementArrowTimeList: [],
    }
  },
  components: {
    LMap,
    LImageOverlay,
    LPolyline,
    LMarker,
    LPopup,
    LIcon,
    LCircle,
    LPolygon,
    LCircleMarker,
    LControl
  },
  created() {
    // this.mapConfig = getMapConfig(this.$bus.mapId)
    this.mapConfig = this.$bus.mapConfig[this.$bus.mapId]
    this.mapOptions = getMapOptions()
    // this.maxBounds = getMaxBounds(this.$bus.mapId)
    this.maxBounds = this.$bus.maxBounds[this.$bus.mapId]
    this.crs = L.CRS.Simple
  },
  mounted() {
    // this.draggable = new L.Draggable(this.$refs.imageRef.mapObject.getElement())
    this.$nextTick(() => {
      if (this.$bus.mapControlsFlag.airlineFlag) {
        this.addAirLine()
      }
    })

  },
  beforeDestroy() {
    this.$refs.mapRef.mapObject.removeEventListener('zoom', this.onZoom)
  },
  watch: {
    '$bus.movementData': {
      handler(val) {
        this.movementArrowHeadList.forEach((layer) =>{
          this.$refs.mapRef.mapObject.removeLayer(layer)
        })
        this.movementArrowHeadList = []
        this.movementArrowTimeList.push(this.moveTime)
        this.movementDataList.push(val)
        this.$bus.movementData.forEach((item, index) => {
          this.$nextTick(() => {
            this.movementArrowHead = window.L.polylineDecorator(
              this.$refs.polylineRef[index].mapObject,
              {
                patterns: [
                  {
                    offset: `100%`,
                    repeat: 0,
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 8,
                      polygon: false,
                      pathOptions: {
                        color: this.getTeamPolylineColor(item),
                        fillRule: 'evenodd',
                        fillOpacity: 1,
                        fillColor: this.getTeamPolylineColor(item),
                        stroke: true
                      }
                    })
                  },
                ]
              }).addTo(this.$refs.mapRef.mapObject);
            this.movementArrowHeadList.push(this.movementArrowHead)
          })
        })
      },
      deep: true
    },
    '$bus.mapControlsFlag.airlineFlag' : {
      handler(val) {
        if (val) {
          // 添加airline
          this.addAirLine()
        }else {
          // 清除airline
          if (this.arrow !== undefined && this.arrowHead !== undefined) {
            this.$refs.mapRef.mapObject.removeLayer(this.arrowHead)
            this.$refs.mapRef.mapObject.removeLayer(this.arrow)
          }
        }
      },
      deep: true
    },
    '$bus.mapControlsFlag.movementFlag' : {
      handler(val) {
        if (val) {
          // 添加轨迹箭头
          this.movementArrowHeadList.forEach((item) =>{
            item.setStyle({opacity: 1, fillOpacity: 1})
          })
        } else {
          // 清除轨迹的箭头
          this.movementArrowHeadList.forEach((item) =>{
            item.setStyle({opacity: 0, fillOpacity: 0})
          })
        }
      },
      deep: true
    }
  },
  computed: {
    mapNameLatLng() {
      const X = this.$bus.maxBounds[this.$bus.mapId][0][1][0]
      const Y= this.$bus.maxBounds[this.$bus.mapId][0][1][1]
      let latlng = []
      switch (this.$bus.mapId) {
        case 1:
          latlng = [Y - 100 * 1.9, X + 126 * 1.7]
          break;
        case 3:
          latlng = [Y - 100 * 0.8, X + 126 * 0.8]
          break;
        case 4:
          latlng = [Y - 100 * 0.5, X + 126 * 0.6]
          break;
        case 5:
          latlng = [Y - 100 * 0.5, X + 126 * 0.6]
          break;
        case 14:
          latlng = [Y - 100 * 0.5, X + 126 * 0.6]
          break;
        case 22:
          latlng = [Y - 100 * 0.5, X + 126 * 0.6]
          break;
      }
      return latlng
    },
    getMapName() {
      return  icon({
        iconUrl: require(`@/assets/images/${this.$bus.getIsMuti ? 'heatmap_match' : 'heatmap_team'}.png`),
        iconSize: [126 * 0.8, 40 * 0.8],
        // iconAnchor: [10, 20]
        className: 'isClassName'
      })
    },
    getTime() {
      return (time) =>
        `${this.$bus.onConvertSeconds(
          time,
          'mm:ss'
        )}`
    },
    getMovementLatLngs() {
      return (player) =>
        player.map((item) => [
          item.posZ * getMapScale(this.$bus.mapId),
          item.posX * getMapScale(this.$bus.mapId)
        ])
    },
    getLatLngs() {
      return (player) => [
        player.posZ * getMapScale(this.$bus.mapId),
        player.posX * getMapScale(this.$bus.mapId)
      ]
    },
    getBombRadius() {
      return (player) => player.radius * getMapScale(this.$bus.mapId)
    },
    getActivepointRadius() {
      // return 20 * getMapScale(this.$bus.mapId)
      return 8
    },
    getSafeZoneLatLngs() {
      const { time_span_type, inner_center_z, inner_center_x } =
        this.$bus.safeZoneData
      if (time_span_type === 1 || time_span_type === 2) {
        return [
          inner_center_z * getMapScale(this.$bus.mapId),
          inner_center_x * getMapScale(this.$bus.mapId)
        ]
      }
      return []
    },
    getPoisonLatLngs() {
      const { time_span_type, outer_center_z, outer_center_x, outer_radius } =
        this.$bus.safeZoneData
      if (time_span_type === 1) {
        return this.onGetImageOverLayPoints(
          this.$bus.mapId,
          this.onGetCirclePoints(outer_radius * getMapScale(this.$bus.mapId), {
            x: outer_center_z * getMapScale(this.$bus.mapId),
            y: outer_center_x * getMapScale(this.$bus.mapId)
          })
        )
      }
      if (time_span_type === 2) {
        const { r, x, z } = this.onLerpPoints(
          this.$bus.safeZoneData,
          this.$bus.sliderValue
        )
        return this.onGetImageOverLayPoints(
          this.$bus.mapId,
          this.onGetCirclePoints(r * getMapScale(this.$bus.mapId), {
            x: z * getMapScale(this.$bus.mapId),
            y: x * getMapScale(this.$bus.mapId)
          })
        )
      }
      return []
    },
    getAirlineLatLngs() {
      const arr = []
      this.$bus.airlineData.map(item => {
        arr.push([ item.start_z * getMapScale(this.$bus.mapId), item.start_x * getMapScale(this.$bus.mapId)])
        arr.push([item.end_z  * getMapScale(this.$bus.mapId), item.end_x  * getMapScale(this.$bus.mapId)])
      })
      return arr
    },
    getSafeZoneRadius() {
      const { time_span_type, inner_radius } = this.$bus.safeZoneData
      if (time_span_type === 1 || time_span_type === 2) {
        return inner_radius * getMapScale(this.$bus.mapId)
      }
      return 0
    },
    getPointIconUrl() {
      return (iconType, playerInfo) => {
        let index = 0
        if (playerInfo !== undefined) {
          index = Number(getIconIndex(this.getTeamActivePointColor(playerInfo)))
        }
        return  icon({
          iconUrl: require(`@/assets/images/icon-${iconType === 'deadpoint' || iconType === 'landingpoint' ? iconType + '-' + (index + 1) : iconType}.png`),
          iconSize: [20, 20],
          // iconAnchor: [10, 20]
          className: 'isClassName'
        })
      }
    },
    getTeamPolylineColor() {
      return (player) => {
        if (this.$bus.getIsMuti) {
          const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
          const index = matchIdList.findIndex(
            (matchId) => matchId === player[0].matchId
          )
          return getMatchColor(index).color
        }
        if (!player.length) {
          return ''
        }
        const { team_id } = this.onGetUserInfo(player[0].userId)
        return getTeamColor(team_id - 1)
      }
    },
    getMatchIdStyle() {
      return (matchId) => {
        const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
        const index = matchIdList.findIndex((x) => x === matchId)
        return { color: `${getMatchColor(index).color}` }
      }
    },
    getTeamActivePointColor() {
      return (player) => {
        if (this.$bus.getIsMuti) {
          const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
          const index = matchIdList.findIndex(
            (matchId) => matchId === player.matchId
          )
          return getMatchColor(index).color
        }
        const { team_id } = this.onGetUserInfo(player.userId)
        return getTeamColor(team_id - 1)
      }
    },
    getPlayerReviveLatLngs() {
      return (player) => [
        player.posZ * getMapScale(this.$bus.mapId),
        player.posX * getMapScale(this.$bus.mapId)
      ]
    },
    getBuyShopLatLngs() {
      return (player) => [
        player.posZ * getMapScale(this.$bus.mapId),
        player.posX * getMapScale(this.$bus.mapId)
      ]
    },
    getHotzoneLatLngs() {
      return (player) => [
        player.posZ * getMapScale(this.$bus.mapId),
        player.posX * getMapScale(this.$bus.mapId)
      ]
    }
  },
  methods: {
    onMapReady() {
      this.$refs.mapRef.mapObject.addEventListener('zoom', this.onZoom)
    },
    onLerpPoints(data, time) {
      const {
        outer_center_z,
        outer_center_x,
        inner_center_x,
        inner_center_z,
        outer_radius,
        inner_radius,
        start_time,
        end_time
      } = data
      const t = Math.min(
        (time * 1000 - start_time) / (end_time - start_time),
        1
      )
      const r = lerp(outer_radius, inner_radius, t)
      const x = lerp(outer_center_x, inner_center_x, t)
      const z = lerp(outer_center_z, inner_center_z, t)
      return {
        z,
        x,
        r
      }
    },
    onGetImageOverLayPoints(mapId = 1, points = []) {
      // if (mapId === 1) {
        return [
          [this.$bus.mapVertexConfig[mapId].bottom_right_x * getMapScale(mapId), this.$bus.mapVertexConfig[mapId].bottom_right_y * getMapScale(mapId)],
          ...points,
          [this.$bus.mapVertexConfig[mapId].bottom_right_x * getMapScale(mapId), this.$bus.mapVertexConfig[mapId].bottom_right_y * getMapScale(mapId)],
          [this.$bus.mapVertexConfig[mapId].top_right_x * getMapScale(mapId), this.$bus.mapVertexConfig[mapId].top_right_y * getMapScale(mapId)],
          [this.$bus.mapVertexConfig[mapId].top_left_x * getMapScale(mapId), this.$bus.mapVertexConfig[mapId].top_left_y * getMapScale(mapId)],
          [this.$bus.mapVertexConfig[mapId].bottom_left_x * getMapScale(mapId), this.$bus.mapVertexConfig[mapId].bottom_left_y * getMapScale(mapId)],
        ]
      // }
      // if (mapId === 3) {
      //   return [
      //     // [1475 * getMapScale(mapId), -1475 * getMapScale(mapId)],
      //     // [1475 * getMapScale(mapId), 1475 * getMapScale(mapId)],
      //     // [-1475 * getMapScale(mapId), 1475 * getMapScale(mapId)],
      //     // [-1475 * getMapScale(mapId), -1475 * getMapScale(mapId)],
      //     // ...points,
      //     // [-1475 * getMapScale(mapId), -1475 * getMapScale(mapId)]
      //     [733 * getMapScale(mapId), -987 * getMapScale(mapId)],
      //     ...points,
      //     [733 * getMapScale(mapId), -987 * getMapScale(mapId)],
      //     [733 * getMapScale(mapId), 813 * getMapScale(mapId)],
      //     [-1067 * getMapScale(mapId), 813 * getMapScale(mapId)],
      //     [-1067 * getMapScale(mapId), -987 * getMapScale(mapId)]
      //   ]
      // }
      //
      // if (mapId === 4) {
      //   return [
      //     [700 * getMapScale(mapId), -700 * getMapScale(mapId)],
      //     ...points,
      //     [700 * getMapScale(mapId), -700 * getMapScale(mapId)],
      //     [700 * getMapScale(mapId), 700 * getMapScale(mapId)],
      //     [-700 * getMapScale(mapId), 700 * getMapScale(mapId)],
      //     [-700 * getMapScale(mapId), -700 * getMapScale(mapId)]
      //   ]
      // }
      // if (mapId === 5) {
      //   return [
      //     [900 * getMapScale(mapId), -900 * getMapScale(mapId)],
      //     ...points,
      //     [900 * getMapScale(mapId), -900 * getMapScale(mapId)],
      //     [900 * getMapScale(mapId), 900 * getMapScale(mapId)],
      //     [-900 * getMapScale(mapId), 900 * getMapScale(mapId)],
      //     [-900 * getMapScale(mapId), -900 * getMapScale(mapId)]
      //   ]
      // }
      // todo...
    },
    onGetCirclePoints(r, pos) {
      const circlePoints = []
      const ra = Math.PI / 180
      // for (let i = 90; i <= 450; i++) {
      //   const x = pos.x + r * Math.sin(ra * i)
      //   const y = pos.y + r * Math.cos(ra * i)
      //   circlePoints.unshift([x, y])
      // }
      for (let i = 181; i <= 540; i++) {
        let j = i === 540 ? 181 : i
        const x = pos.x + r * Math.sin(ra * j)
        const y = pos.y + r * Math.cos(ra * j)
        circlePoints.unshift([x, y])
      }
      return circlePoints
    },
    // onToggleDraging() {
    //   if (this.$bus.mapZoom <= 0) {
    //     this.$refs.mapRef.mapObject.dragging.disable()
    //   } else {
    //     this.$refs.mapRef.mapObject.dragging.enable()
    //   }
    // },
    onZoom() {
      this.$bus.mapZoom = this.$refs.mapRef.mapObject.getZoom()
      // this.onToggleDraging()
    },
    onZoomOut() {
      this.$bus.handleGA('zoom_out','click')
      this.$refs.mapRef.mapObject.zoomOut()
    },
    onZoomIn() {
      this.$bus.handleGA('zoom_in','click')
      this.$refs.mapRef.mapObject.zoomIn()
    },
    onReset() {
      this.$refs.mapRef.mapObject.setView(
        [this.mapConfig.centerY, this.mapConfig.centerX],
        this.$bus.mapZoom
      )
    },
    onGetUserInfo(userId) {
      const matchId = this.$bus.getActiveMatchId
      return this.$bus.matchMeta[matchId].team_info.find((data) =>
        data.userId.includes(userId)
      )
    },
    onGetTeamInfo(userId) {
      const matchId = this.$bus.getActiveMatchId
      return this.$bus.accountMeta[matchId].find((data) =>
        data.userId.includes(userId)
      )
    },
    addAirLine() {
      // 添加航线 虚线，带箭头，带icon
      this.arrow = L.polyline(this.getAirlineLatLngs, {
        color: '#D9D9D9',
        dashArray: '10, 10',
      }).addTo(this.$refs.mapRef.mapObject);
      this.arrowHead = window.L.polylineDecorator(
        this.arrow,
        {
          patterns: [
            {offset: `100%`, repeat: 0, symbol: L.Symbol.arrowHead({pixelSize: 12, polygon: true, pathOptions: {color: '#D9D9D9',fillRule: 'nonzero',fillOpacity: 1, fillColor: '#D9D9D9', stroke: true}})},
            { offset: '85%', repeat: 0, symbol: L.Symbol.marker({rotate: true, markerOptions: {
                  icon: L.icon({
                    iconUrl: 'https://dl.dir.freefiremobile.com/common/web_event/playertrack/images/icon_airplane_up.png',
                    iconAnchor: [25, 20]
                  })
                }})}
          ]
        }).addTo(this.$refs.mapRef.mapObject);
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-map {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent !important;

  .popup-point {
    font-family: $ro-font !important;
    color: $el-text !important;

    .popup-point-matchid,
    .popup-point-title,
    .popup-point-subtitle {
      padding: 5px 0 !important;
      font-weight: 700 !important;
      font-size: 14px !important;

      @include lg {
        font-size: 12px !important;
      }
    }

    .popup-point-title {
      @include flexac;
      justify-content: space-between;
      & > p {
        margin: 0;
      }
    }

    .popup-point-subtitle {
      @include flexac;
      font-size: 12px !important;

      @include lg {
        font-size: 10px !important;
      }

      .popup-point-icon {
        width: 14px;
        height: 14px;
        margin-right: 4px;

        &.popup-point-icon__deadpoint {
          background: url('../../assets/images/icon-deadpoint.png') top center /
            100% 100% no-repeat;
        }

        &.popup-point-icon__landingpoint {
          background: url('../../assets/images/icon-landingpoint.png') top
            center / 100% 100% no-repeat;
        }
      }
    }

    .popup-point-text {
      @include flexac;
      padding: 5px 0 !important;
      font-weight: 400 !important;
      font-size: 12px !important;

      @include lg {
        font-size: 10px !important;
      }
    }
  }

  & /deep/ .leaflet-popup-content-wrapper {
    background: $bg-map-control !important;
    border-radius: 5px;
  }

  & /deep/ .leaflet-popup-tip {
    background: $bg-map-control !important;
  }

  & /deep/ .isMapName {
    z-index: 401;
    position: relative;
    top: px2vw(136);
    left: px2vh(20);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 5px 20px;
    color: #cccccc;
    font-size: 12px;
    display: inline-block;
  }
}
</style>
