<template>
  <div class="widget-panel" :class="layer === 0 ? 'widget-panel--bottom' : ''">
    <div
      class="widget-panel-item"
      v-for="matchId in getMatchIdList"
      :key="matchId"
    >
      <span
        class="panel-item-triangle"
        :class="layer === 0 ? 'panel-item-triangle--bottom' : ''"
      ></span>
      <span class="panel-item-dot" :style="getColor(matchId)"></span>
      <span class="panel-item-matchid">{{ matchId }}</span>
      <span class="panel-item-info">
        {{
          $bus.onConvertSeconds(
            getPlayerInfo(matchId).match_dump.survival_time,
            'm[m] ss[s]'
          )
        }}
        | {{ getPlayerInfo(matchId).match_dump.kills }} kills
      </span>
    </div>
  </div>
</template>

<script>
import { getMatchColor } from '@/utils/utils'

export default {
  props: {
    userId: {
      type: String,
      default: ''
    },
    teamId: {
      type: Number,
      default: 0
    },
    layer: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getMatchIdList() {
      return this.$bus.matchIdList
        .slice()
        .filter((item) => item.selected)
        .map((x) => x.matchId)
        .filter((matchId) =>
          this.$bus.accountMeta[matchId].some(
            (x) => x.userId === this.userId && x.team_id === this.teamId
          )
        )
    },
    getColor() {
      return (matchId) => {
        const matchIdList = this.$bus.matchIdList.map((x) => x.matchId)
        const index = matchIdList.findIndex((x) => x === matchId)
        return {
          backgroundColor: getMatchColor(index).color
        }
      }
    }
  },
  methods: {
    getPlayerInfo(matchId) {
      return this.$bus.accountMeta[matchId].find(
        (item) => item.userId === this.userId
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-panel {
  position: absolute;
  top: unset;
  bottom: px2vh(28);
  right: 0;
  width: px2vw(370);
  border-radius: 5px;
  padding: px2vh(15) px2vw(20);
  background-color: $el-form;
  color: $el-text;
  font-size: 12px;
  z-index: 100;

  &.widget-panel--bottom {
    top: px2vh(-72);
    bottom: unset;
  }

  @include float {
    // width: px2vw(307);
    width: calc(100% - 16px - 20 / 1920 * 100vw);
  }

  @include lg {
    font-size: 10px;
  }

  .widget-panel-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: px2vh(25);
  }

  .panel-item-triangle {
    position: absolute;
    top: unset;
    bottom: px2vh(-22);
    left: px2vw(223 + 63);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top: 12px solid $el-form;

    &.panel-item-triangle--bottom {
      border: 12px solid transparent;
      border-bottom: 12px solid $el-form;
      top: px2vh(70);
      bottom: unset;
      transform: rotate(180deg);
    }

    @include float {
      left: px2vw(362 + 63);
    }
  }

  .panel-item-dot {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: $el-text;
  }

  .panel-item-matchid {
    padding-left: px2vw(4);
  }

  .panel-item-info {
    margin-left: auto;
  }
}
</style>
